import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { thexanadu, kokumstore, privateearth } from '../Model/Project'



const Airportzo = () => {
    return (
      <>
          <Header />
  
          <div className="vinveli-head">
              <div className="container vinveli-head-cont">
                  <h2 className='text-animate'>Airportzo</h2>
                  <h3 className='text-animate'>A seamless online experience for a <br className="d-none d-lg-block" /> seamless travel experience provider.</h3>
              </div>
          </div>
  
          <div className="container text-animate">
              <img className="img-fluid vinveli-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/b2f8dd55-023021-2048x1215.png" alt="vinveli-banner" />
          </div>
  
          <div className="container vinveli-obj">
              <div className="row">
                  <div className="vinveli-obj-cont">
                      <h5 className="border-bottom text-animate">OBJECTIVE</h5>
                      <p className='text-animate'>Airport Zo is a service provider that is positioned to change the way people travel. To create a perception of the brand as a partner that delivers hassle-free airport experiences, we created a fresh new look for the website and made it a lot easier to navigate and more user-friendly as well. The fact that Airport Zo, helps customers experience a hassle-free airport experience from the moment they leave their homes to the moment they reach their destination was put up front and centre. With additional features like personal assistance, lounge and baggage porter services, also being highlighted, we were able to enable marketing activities much more easily increasing traffic to the website significantly.</p>
                  </div>
              </div>
          </div>
  
          <div className="vinveli-wf">
              <div className="container vinveli-wf-cont">
                  <h5 className="border-bottom text-animate">WIREFRAMES</h5>
              </div>
  
              <div className="container text-animate">
                  <img className="img-fluid vinveli-wf-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/92564b50-group-2705-2048x1583.png" alt="vinveli-banner" />
              </div>
          </div>
  
          {/* <div className="author-dsys">
              <div className="container author-dsys-cont">
                  <h5 className="border-bottom text-animate">DESIGN SYSTEM</h5>
  
                  <div className="row">
                      <div className="col-lg-6 col-md-6 author-dsys-text text-animate">
                          <img className="img-fluid author-dsys-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/145cdd00-group-2661.png" alt="My-persona-banner" />
                      </div>
                      <div className="col-lg-6 col-md-6 author-dsys-text text-animate">
                          <img className="img-fluid author-dsys-img0" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/f4493cb5-group-2662.png" alt="My-persona-banner" />
                      </div>
                  </div>
  
                  <div className="row">
                      <div className="col-lg-6 col-md-6 author-dsys-text text-animate">
                          <img className="img-fluid author-dsys-img1" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/241c3046-group-2663.png" alt="My-persona-banner" />
                      </div>
                      <div className="col-lg-6 col-md-6 author-dsys-text text-animate">
                          <img className="img-fluid author-dsys-img1" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/62f5cd6d-group-2664.png" alt="My-persona-banner" />
                      </div>
                  </div>
              </div>
          </div> */}
  
          <div className="author-dsys">
              <div className="container author-dsys-cont">
                  <h5 className="border-bottom text-animate" >CARDS TEMPLATE DESIGN</h5>
  
                  <div className="row airportzo-bg">
                      <div className="col-lg-4 col-md-4 text-animate" >
                          <img className="img-fluid airportzo-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/6bd638e4-group-26701.png" alt="My-persona-banner" />
                      </div>
                      <div className="col-lg-4 col-md-4 text-animate" >
                          <img className="img-fluid airportzo-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/a3febd9c-group-256.png" alt="My-persona-banner" />
                      </div>
                      <div className="col-lg-4 col-md-4 text-animate" >
                          <img className="img-fluid airportzo-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/8dacb67a-group-257.png" alt="My-persona-banner" />
                      </div>
                  </div>
              </div>
          </div>
  
          <div className="air-portzo-img">
              <div className="container airportzo-img-bg text-animate" >
                  <img className="img-fluid airportzo-abo-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/98ceaca2-000vkjxn.png" alt="My-persona-banner" />
              </div>
          </div>
  
          <div className="air-portzo-img">
              <div className="container airportzo-img-bg0 text-animate" >
                  <img className="img-fluid airportzo-abo-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/c09ccedd-65230.png" alt="My-persona-banner" />
              </div>
          </div>
  
          <div className="air-portzo-img">
              <div className="container airportzo-img-bg1 text-animate" >
                  <img className="img-fluid airportzo-abo-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/b80d9c6d-5121-1732x2048.png" alt="My-persona-banner" />
              </div>
          </div>
  
          <div className="container">
              <div className="border-bb"></div>
          </div>
  
          <div>
              <div className="container sotia text-animate">
                  <h2>See our thinking in action.</h2>
                  <div className="row">
                      <div className="col-lg-4 col-md-12">
                          <Seeourthinking 
                              title={thexanadu.title}
                              description={thexanadu.description}
                              pageUrl={thexanadu.pageUrl}
                              imageUrl={thexanadu.imageUrl}
                          />
                      </div>
                      <div className="col-lg-4 col-md-12">
                          <Seeourthinking 
                              title={kokumstore.title}
                              description={kokumstore.description}
                              pageUrl={kokumstore.pageUrl}
                              imageUrl={kokumstore.imageUrl}
                          />
                      </div>
                      <div className="col-lg-4 col-md-12">
                          <Seeourthinking 
                              title={privateearth.title}
                              description={privateearth.description}
                              pageUrl={privateearth.pageUrl}
                              imageUrl={privateearth.imageUrl}
                          />
                      </div>
                  </div>
              </div>
          </div>
  
  
          <Footer />
      </>
    )
  }
  
  export default Airportzo
