import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { futbank, gbr, bharathihomes } from '../Model/Project'

const VinveliWeb = () => {
  return (
    <>
        <Header />

        <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>Vinveli</h2>
                <h3 className='text-animate'>Bringing the best of the international firearms online, to India.</h3>
            </div>
        </div>

        <div className="container text-animate">
            <img className="img-fluid vinveli-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/a7049d53-1000-1-2048x977.png" alt="vinveli-banner" />
        </div>

        <div className="container vinveli-obj">
            <div className="row">
                <div className="vinveli-obj-cont">
                    <h5 className="border-bottom text-animate"         >OBJECTIVE</h5>
                    <p className='text-animate'           >When Vinveli entered India, the trailblazer in the fields of robotics, automation and logistical infrastructure required a website that could give gun enthusiasts more information about their offerings in the commercial firearms industry. As Vinveli was set to bring the international giants CZ to India, with a range of firearms that had never before been available in the country, we had to enable features on the website for marketing activities. We created a website that propelled the brand forward, gave potential customers all the information they needed and positioned the brand as a leader in the space. Soon everyone in the industry knew that CZ and Vinveli were set to change the face of firearms in India.</p>
                </div>
            </div>
        </div>

        <div className="vinveli-res">
            <div className="container vinveli-res-cont">
                <h5 className="border-bottom text-animate"         >RESEARCH</h5>
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <p className='text-animate'>Research is expensive, so is a failed product. We used Google Analytics and Google Tag Manager to collect the quantitative insights about the old website to figure out the key pain points of the users.</p>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                    <div className="col-lg-5 col-md-6 text-animate"              >
                        <img className="img-fluid" style={{padding: "0 30px 10px 30px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4910d83e-bharathi-homes-image-1.png" alt="vinveli-banner" />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 vinveli-ul">
                        <ul className="vinveli-li text-animate"              >
                            <li>Low Conversion RATE (CTR)</li>
                            <li>Low Persuation states</li>
                            <li>Lack of brand credibility</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 vinveli-ul vinveli-ul-">
                        <ul className="vinveli-li text-animate"           >
                            <li>High Bounce Rates</li>
                            <li>High Drop off Rate</li>
                            <li>Low Pageview Time</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="vinveli-desin">
            <div className="container vinveli-desin-text">
                <h5 className="border-bottom text-animate"         >DESIGN PROCESS</h5>
            </div>

            <div className="container vinveli-desin-text-ul">
                <div className="row pt-3">
                    <div className="col-lg-3 col-md-3 vinveli-desin-ul">
                        <h5 className='text-animate'>Idea</h5>
                        <ul className="vinveli-desin-li text-animate"              >
                            <li>Briefing</li>
                            <li>Research</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 vinveli-desin-ul">
                        <h5 className='text-animate'>UX Analytics</h5>
                        <ul className="vinveli-desin-li text-animate"           >
                            <li>Personas</li>
                            <li>User Journeys</li>
                            <li>User interview</li>
                            <li>Competitor analysis</li>
                            <li>Nielsen heuristics</li>
                            <li>Structure of the website</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 vinveli-desin-ul">
                        <h5 className='text-animate'>UI Design</h5>
                        <ul className="vinveli-desin-li text-animate"           >
                            <li>Wireframes</li>
                            <li>Interaction prototype</li>
                            <li>Visual Design</li>
                            <li>Design system</li>
                            <li>Presentation</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 vinveli-desin-ul">
                        <h5 className='text-animate'>Testing</h5>
                        <ul className="vinveli-desin-li text-animate"           >
                            <li>Usability testing</li>
                            <li>Transfer to development</li>
                            <li>User Feedback &amp; Improvements</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="vinveli-wf">
            <div className="container vinveli-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
            </div>

            <div className="container text-animate"              >
                <img className="img-fluid vinveli-wf-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/9c317131-2v-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="vinveli-bg-img">
            <div className="container text-center text-animate"         >
                <img className="img-fluid vinveli-cov-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/658da6be-2000-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="vinveli-bg-img0">
            <div className="container text-center text-animate"              >
                <img className="img-fluid vinveli-cov-img0" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/f356e888-3000-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="vinveli-bg-img1">
            <div className="container text-center text-animate"              >
                <img className="img-fluid vinveli-cov-img1" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/a0302895-6v-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="vinveli-bg-img2">
            <div className="container text-center">
                <div className="row">
                    <div className="col-lg-4 col-md-4 text-animate"              >
                        <img className="img-fluid vinveli-cov-img2" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/a88f075b-7v1-1.png" alt="vinveli-banner" />
                    </div>
                    <div className="col-lg-4 col-md-4 text-animate"           >
                        <img className="img-fluid vinveli-cov-img2" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/bbf70b13-7v2-1.png" alt="vinveli-banner" />
                    </div>
                    <div className="col-lg-4 col-md-4 text-animate"           >
                        <img className="img-fluid vinveli-cov-img2" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/c4790bc2-7v3-1.png" alt="vinveli-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={gbr.title}
                            description={gbr.description}
                            pageUrl={gbr.pageUrl}
                            imageUrl={gbr.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={bharathihomes.title}
                            description={bharathihomes.description}
                            pageUrl={bharathihomes.pageUrl}
                            imageUrl={bharathihomes.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageUrl={futbank.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default VinveliWeb