import React, { useEffect } from 'react';

const ContactButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <a
        data-tf-slider="e55Hj0lk"
        data-tf-position="right"
        data-tf-opacity="100"
        data-tf-iframe-props="title=Future Contact Form"
        data-tf-open-value="30"
        data-tf-auto-close=""
        data-tf-transitive-search-params=""
        data-tf-medium="snippet"
        style={{ color: '#000000', textDecoration: 'underline', fontSize: '18px', cursor: 'pointer' }}
      >
        Get in touch
      </a>
    </>
  );
};

export default ContactButton;