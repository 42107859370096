import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Services = () => {
  return (
    <>
      <style>
        {
          `
          .footer {
            margin-top: 1px;
          }
          `
        }
      </style>

        <Header />

        <div className="services">
          <div className="container serv-bann">
            <div className="row">
              <div className="col-lg-6 col-md-6 serv-bann-cont">
                <div className="d-block text-animate">
                  <h2>We’ve executed powerfully persuasive creative ideas that have commanded attention across multiple platforms both online and offline.</h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 serv-bann-cont text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ba14cd8b-hand-services-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="serv-capblt text-animate">
            <h2>Our Capabilities</h2>
          </div>
          <div className="row serv-capb-cont ">
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Brand Identity</h2>
              <p>Visual Identity &amp; Naming<br />
                Brand Tone and Brand Personality<br />
                Verbal Identity and Messaging<br />
                Brand Experiences<br />
                Creative Development<br />
                Launch Campaigns and Activation.</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Strategy</h2>
              <p>Brand Strategy<br />
                Positioning Strategy<br />
                Market Research</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Content Solutions</h2>
              <p>Social Media Assets<br />
                Content Strategy &amp; Planning<br />
                Premium Content</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Web Development</h2>
              <p>Experience Development<br />
              Commerce Solutions<br />
              Content Management System (CMS)<br />
              Responsive Design<br />
              Landing Pages</p>
            </div>
            {/* <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Analytics &amp; Reporting​</h2>
              <p>Digital &amp; Performance Marketing<br />
                Social Media Marketing<br />
                Search Engine Marketing<br />
                SEO<br />
                Web Analytics<br />
                Social Analytics</p>
            </div> */}
          </div>

          <div className="row serv-capb-cont">
            
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Mobile Development</h2>
              <p>Mobile Strategy<br />
              Mobile App Development<br />
              Web Services Development &amp;<br />
              Integrations<br />
              Engagement Strategy</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>UI &amp; UX</h2>
              <p>Interactive Experiences<br />
              Experience Design<br />
              User Journey Modelling<br />
              Information Architecture &amp; Wireframing</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Brand Advocacy</h2>
              <p>Online Reputation Management<br />
              Influencer Outreach &amp; Native Content</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Consultation</h2>
              <p>Purpose, Positioning, Proposition<br />
              Stakeholder Alignment Brand &amp;<br />
              Portfolio Architecture</p>
            </div>
          </div>

          <div className="row serv-capb-cont">
            
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Employer Branding &amp;<br /> Employee Engagement</h2>
              <p>Internal Social Media Campaigns<br />
              Intranets, Emails, Newsletters<br />
              Print Magazines &amp; Newsletters.<br />
              Films<br />
              Space &amp; Office Branding</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
            <h2>ATL &amp; BTL</h2>
              <p>Print<br />
              Radio<br />
              OOH<br />
              TVC<br />
              Collaterals<br />
              Stall Branding<br />
              Annual Reports</p>
            </div>
            <div className="col-lg-3 col-md-6 serv-capb-cont text-animate">
              <h2>Photography &amp;<br /> Film Production</h2>
              <p>Ad Films<br />
              Corporate Films<br />
              Digital Films<br />
              Product Photography<br />
              Ecommerce Photography<br />
              Campaign Photography<br />
              Digital Shoot &amp; Edit<br />
              Animation &amp; Motion Graphics<br />
              Stop Motion<br />
              Food and Beverages Shoot</p>
            </div>
          </div>

        </div>

        <div className="serv-bg">
          <div className="container">
            <div className="serv-brand-overview text-animate">
              <h2>Brand Purpose</h2>
              <p>We believe every brand should have a higher purpose that it stands for, other than revenue generation. While we are firm believers in the commercial aspect of marketing, when we delve deep and discover the true reason for a brand to exist, whether it's to make lives better, simpler, faster or happier, we can create communication that resonates with the brand at every level, differentiating it from every other product or service in the market. </p>
            </div>

            <div className="row serv-brand">
              <div className="col-lg-6 col-md-6 serv-brand-cont text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e6e1b8c9-branding-compressed.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
              </div>
              <div className="col-lg-6 col-md-6 serv-brand-cont">
                <div className="d-block text-animate">
                  <h4>Brand Development</h4>
                  <p>We start at the very beginning when it comes to defining your brand. We believe in asking questions. In being curious. Finding out how things tick. What makes them tock. We arrive at a brand vision and strategy, and only then do we develop meaningful, cohesive brand personalities and engaging brand experiences both online and off-line that re-define market landscapes.</p>
                </div>
              </div>
            </div>

            <div className="row serv-brand flex-dire">
              <div className="col-lg-6 col-md-6 serv-brand-cont">
                <div className="d-block text-animate">
                  <h4>Strategic Strength</h4>
                  <p>Our strategic strength lies in years of commercial experience and marketing insight. Through a process of continual testing, planning and adjustment, we ensure that we provide our clients with strategically sound points of difference that will not only help them stand apart in a crowded market but will also help create clutter-breaking work.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 serv-brand-cont text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cb30aef7-strategy-final.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
              </div>
            </div>

            <div className="row serv-brand">
              <div className="col-lg-6 col-md-6 serv-brand-cont text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e003a266-digital-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
              </div>
              <div className="col-lg-6 col-md-6 serv-brand-cont">
                <div className="d-block text-animate">
                  <h4>Digital Expertise</h4>
                  <p>Through a combination of strategic intelligence and digital expertise, we design and create powerful digital experiences across platforms. We identify creative challenges and deliver beautiful, yet effective interface design, integrating creativity with the highest levels of technology.</p>
                </div>
              </div>
            </div>

            <div className="row serv-brand flex-dire">
              <div className="col-lg-6 col-md-6 serv-brand-cont">
                <div className="d-block text-animate">
                  <h4>Creative Excellence</h4>
                  <p>Our team of creative thinkers have won multiple awards over the years. Through a combination of designers, illustrators, writers, content producers, photographers, videographers, strategists and planners, we harness creative ideas that are Not Afraid to jump out at you, no matter what the platform. <br /><br />
                  Original thinking, creative flair and a fearlessness to try something bold, fresh and new that’s commercially viable sets our creative team apart. Our campaigns trigger genuine emotion, prompt conversation and most importantly, drive purchase behaviour.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 serv-brand-cont text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ab95fe5b-services-implementation-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
              </div>
            </div>

          </div>
        </div>

        <Footer />
    </>
  )
}

export default Services