import React from 'react'

const Workpages = (props) => {
  return (
    <div className="work_pages">
        <a href={props.pageUrl} target="_blank" rel="noopener noreferrer">
          <img className="text-animate" style={{width: "100%"}} src={props.imageUrl} alt="Finger_Prints" />
          <h3 className='text-animate'>{props.title}</h3>
          <h4 className='text-animate'>{props.description}{props.descr}</h4>
        </a>
    </div>
  )
}

export default Workpages