import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { writerscafe, abbys, desidi } from '../Model/Project'

const Amelies = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Amelies​</h2>
                <h3 className='text-animate'>The Birth of a Mysterious Woman.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/9701655e-banner.png" alt="intellect-banner" />
        </div>

        <div className="container curiohh-over">
            <div className="row">
                <div className="col-lg-6 amelie-resp">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/9c233b51-logo.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-6 curiohh-cont amelie-resp">
                    <div className="d-block">
                        <h4>The Birth of a Mysterious Woman</h4>
                        <p className="p-0">We were tasked with creating the identity for a European cafe and ice cream parlour. With an influx of new entrants In the marketplace Amélie was born. Amélie, it is believed, lived in a noisy, infamous Rue in Paris, back in the fashionable 50’s – just above her famous ice cream and treatery shop. Creating a personal yet mysterious woman behind the scene, Amélie teased her way into the market and helped build a distinct brand voice. Special menus and one off seasonal flavours tied into the mystery of the brand and had the curious coming back for more.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container mehta-jewell-cont">
            <p>Fingerprints built the Brand Identity, Brand Voice, Signage Systems, Packaging, Social Media Marketing and Print Communication to announce her arrival. Amélie always said that food wasn’t just for the body but for the soul too. When asked how she created something so sweet with so little sugar, she’d say, “Chérie, it is love! It is naughty, it is playful, it is fun.“</p>
        </div>

        <div className="container position-relative">
            <div className="row bayleaf-ban-img">
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/ccf3a4a1-signboard.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/340fc51f-image2.png" alt="intellect-banner" />
                </div>

                <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/48a299e8-menu.png" alt="intellect-banner" />

                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/c90d185a-box-1.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/719cc23d-box-2.png" alt="intellect-banner" />
                </div>

                <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/dffdddbd-box-4.png" alt="intellect-banner" />

                <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/2fb2a53a-box-3.png" alt="intellect-banner" />
            </div>
        </div>


        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageUrl={writerscafe.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={abbys.title}
                            description={abbys.description}
                            pageUrl={abbys.pageUrl}
                            imageUrl={abbys.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={desidi.title}
                            description={desidi.description}
                            pageUrl={desidi.pageUrl}
                            imageUrl={desidi.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Amelies
