import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { freshey, shuddha, aniva } from '../Model/Project'

const Kitchenji = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>KitchenJi</h2>
                <h3 className='text-animate'>Your kitchen companion that<br /> makes every day easy!</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e09f6f11-ffdsaaf-1.png" alt="intellect-banner" />
        </div>

        <div className="container kitchen-cont">
            <h4>Confident to grow</h4>
            <p>Kitchenji needed to revitalise the brand as it looked to enter new markets and regain<br className="d-none d-lg-block d-md-block" /> its market share. Fingerprints engaged in market research and crafted the brand<br className="d-none d-lg-block d-md-block" /> logo, identity and packaging.</p>
        </div>

        <div className="container text-animate position-relative bayleaf-ban-img">
            <video className="lexotique-video" width={"250"} height={"320"} src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/6c241682-kitchen-ji-logo.mp4.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
        </div>

        <div className="container position-relative kitchen-ji-cont">
            <p>Simplifying and amplifying the visual identity.</p>

            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8591260e-sddsfa-1.png" alt="intellect-banner" />
        </div>

        <div className="container position-relative bayleaf-ban-img">
            <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/06899ec1-logo-size.png" alt="intellect-banner" />

            <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a3ffe0ba-logo-do-donot.png" alt="intellect-banner" />

            <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/536929c2-groundnut-oil2-1-1.png" alt="intellect-banner" />

            <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/1d84f181-coconut-oil-1.png" alt="intellect-banner" />

            <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/18f6de17-seame-oil2-1-1.png" alt="intellect-banner" />
        </div>

        <div className="container position-relative kitchen-ji-cont">
            <p>Hierarchical design principles</p>

            <img className="img-fluid w-50" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2fd8498b-fafafa-1.png" alt="intellect-banner" />

            <div className="row">
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img w-50" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8982f8f5-red-chilli-pastre-1.png" alt="intellect-banner" />
                    <p>Frustration-free and practical forms</p>
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img w-50" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/4fe9a522-garlic-parste-1.png" alt="intellect-banner" />
                    <p>Designed with the shelf in mind</p>
                </div>
            </div>

            <div className="container">
                <div className="border-bb"></div>
            </div>

            <div className="row kitchen-ji-cont">
                <p>Structured design with versatility</p>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img w-50" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/61fdf2e9-mayo.jpg" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img w-50" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/82d4c4f2-ketchurp-1.png" alt="intellect-banner" />
                </div>
            </div>
        </div>



        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={freshey.title}
                            description={freshey.description}
                            pageUrl={freshey.pageUrl}
                            imageUrl={freshey.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={shuddha.title}
                            description={shuddha.description}
                            pageUrl={shuddha.pageUrl}
                            imageUrl={shuddha.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={aniva.title}
                            description={aniva.description}
                            pageUrl={aniva.pageUrl}
                            imageUrl={aniva.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Kitchenji
