import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Projectworks from '../Components/Projectworks'
import { sunnybee, asianpaints, intellect, freshey, vinveli, abbys, authorcafe, mehta } from '../Model/Project'

const Home = () => {
  return (
    <>
      <Header />
        <section className="banner">
            <div className="banner__content">
                <div className="container">
                    <div className="row banner__text">
                        <div className="col-lg-8 col-md-12">
                            <h1 className='text-animate'>We create impactful work that’s <br className="d-none d-md-block" /> strategically sound and creatively fearless to help brands and businesses grow.</h1>
                            <div className="banner__btn text-animate">
                                <a href="/work">See all work</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="overview">
            <div className="container text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/6004b4f4-home-video.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                <div className="overview-cont">
                    <h2 className="text-animate">We help brands connect with audiences through intelligent design, insightful <br className="d-lg-block d-none" /> strategy&nbsp; and ever-evolving technology to create more powerful brand experiences.&nbsp;<br className="d-lg-block d-none" /> We approach challenges with creativity. We collaborate with our clients.&nbsp;We create <br className="d-lg-block d-none" /> work that not only drives market share but ultimately moves people.<br /><br />We’re not afraid and we help brands be fearless.</h2>
                </div>
            </div>
        </div>

        <div className="proj-works">
            <div className="container text-animate">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <Projectworks 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            videoUrl={sunnybee.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 proj-works-top">
                        <Projectworks 
                            title={asianpaints.title}
                            description={asianpaints.description}
                            pageUrl={asianpaints.pageUrl}
                            videoUrl={asianpaints.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <Projectworks 
                            title={intellect.title}
                            description={intellect.description}
                            pageUrl={intellect.pageUrl}
                            videoUrl={intellect.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 proj-works-top">
                        <Projectworks 
                            title={freshey.title}
                            description={freshey.description}
                            pageUrl={freshey.pageUrl}
                            videoUrl={freshey.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <Projectworks 
                            title={vinveli.title}
                            description={vinveli.description}
                            pageUrl={vinveli.pageUrl}
                            videoUrl={vinveli.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 proj-works-top">
                        <Projectworks 
                            title={abbys.title}
                            description={abbys.description}
                            pageUrl={abbys.pageUrl}
                            videoUrl={abbys.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <Projectworks 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            videoUrl={authorcafe.videoUrl}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 proj-works-top">
                        <Projectworks 
                            title={mehta.title}
                            description={mehta.description}
                            pageUrl={mehta.pageUrl}
                            videoUrl={mehta.videoUrl}
                        />
                    </div>

                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div className="container">
            <div className="our-client">
                <h4>Clients we work with</h4>
                <p>Our goal is to create purposeful work that works for <br className="d-none d-lg-block d-md-block" /> your audience and your business.</p>

                <div className="d-none d-lg-block d-md-block">
                    <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/439668c1-logos.png" alt="Finger-prints" />
                </div>

                <div className="d-lg-none d-md-none">
                    <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/ec0d56cf-logo-m.png" alt="Finger-prints" />
                </div>
            </div>
        </div>


      <Footer />
    </>
  )
}

export default Home
