import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { desidi, amelies, vapho } from '../Model/Project'

const Writerscafe = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Writer’s Café</h2>
                <h3 className='text-animate'>The write kind of branding.</h3>
            </div>
        </div>

        <div className="container text-animate writercafe-bann">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/cc833815-cafe5-e1-1.png" alt="intellect-banner" />
        </div>

        <div className="container">
            <div className='text-animate text-center writer-cafe'>
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9807d29f-logof-original-1.png" alt="intellect-banner" />
            </div>

            <div className="row writercafe-img">
                <div className="col-lg-6 col-md-6 writercafe-img">
                    <div className='text-animate'>
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e370b10c-logo-rgeverse-1.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 writercafe-img">
                    <div className='text-animate'>
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/f65305d7-lofgo-construct-1-1.png" alt="intellect-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="writercafe-bg">
            <div className="container writercafe-cont text-center">
                <h2>Ditch The Sympathy, Bring Your Appetite!</h2>
                <p>Writer’s café is doing the write thing in more ways than one. Managed by<br className="d-none d-lg-block" /> survivors of acid attacks, it is also Chennai’s first Swiss café. We were happy<br className="d-none d-lg-block" /> to support them across various branding<br className="d-none d-lg-block" /> and marketing requirements.</p>
            </div>

            <div className="container text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2d7bb511-6fa-1-1.png" alt="intellect-banner" />
            </div>
        </div>

        <div className="container">
            <div className="para-cont">
                <p>Fingerprints built the brand's visual and verbal identity that was applied across packaging,<br className="d-none d-lg-block" /> menu cards, interior wall graphics and other digital and print communication.</p>

                <img className="img-fluid" style={{width: "100%", padding: "10px 0"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/ace9f81c-gfd-1.png" alt="intellect-banner" />

                <img className="img-fluid" style={{width: "100%", padding: "10px 0"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/57803db0-fgd-1.png" alt="intellect-banner" />

                <img className="img-fluid" style={{width: "100%", padding: "10px 0"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/21bf1c86-gdffgd-1.png" alt="intellect-banner" />

                <img className="img-fluid" style={{width: "100%", padding: "10px 0"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/4c992ce4-gdgfdgfd-1.png" alt="intellect-banner" />

                <div className="row" style={{padding: "12px"}}>
                    <div className="col-md-4 p-0">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/bdd6d261-gassga-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-4 p-0">
                        <img className="img-fluid writer-cafe-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/621ab709-av-f-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-4 p-0">
                        <img className="img-fluid writer-cafe-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8ad01157-cookies-1-768x768.jpeg" alt="intellect-banner" />
                    </div>
                </div>

                <img className="img-fluid" style={{width: "100%", padding: "10px 0"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c1162c3b-gassaga-1.png" alt="intellect-banner" />

                <img className="img-fluid" style={{width: "100%", padding: "10px 0"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/33c3fe1a-varte-1.png" alt="intellect-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={desidi.title}
                            description={desidi.description}
                            pageUrl={desidi.pageUrl}
                            imageUrl={desidi.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={amelies.title}
                            description={amelies.description}
                            pageUrl={amelies.pageUrl}
                            imageUrl={amelies.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vapho.title}
                            description={vapho.description}
                            pageUrl={vapho.pageUrl}
                            imageUrl={vapho.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Writerscafe