import Helmet from 'react-helmet'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import CareerForm from './CareerForm'

const uiuxJson = {
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "title" : "UI/UX Designer",
    "description" : "<p>Fingerprints aspires to be an organization that reflects the globally diverse audience that our products and technology serve. We believe that in addition to hiring the best talent, a diversity of perspectives, ideas and cultures leads to the creation of better products and services.We are looking for a UI/UX Designer to turn our software into easy-to-use products for our clients. UI/UX Designer responsibilities include gathering user requirements, designing graphic elements and building navigation components. To be successful in this role, you should have experience with design software and wireframe tools. Ultimately, you’ll create both functional and appealing features that address our clients’ needs and help us grow our customer base.</p>",
    "identifier": {
      "@type": "PropertyValue",
      "name": "Fingerprints",
      "value": "1234567"
    },
    "datePosted" : "2022-05-26",
    "validThrough" : "2022-12-26T00:00",
    "employmentType" : "FULL_TIME",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : "Fingerprints",
      "sameAs" : "https://www.fingerprintscreative.com/opportunities/ui-ux-designer",
      "logo" : "https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/da33fd4d-mainlogo.svg"
    },
    "jobLocation": {
    "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "15/4, 3rd Floor, Haddows Lane, Haddows Road, Nungambakkam, Chennai - 600006",
      "addressLocality": "Chetpet",
      "addressRegion": "Chennai",
      "postalCode": "600031",
      "addressCountry": "India"
      }
    }
  }


const Uiux = () => {


    return (
        <>  

            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(uiuxJson)}
                </script>
            </Helmet>
            <Header />
            <div className="opportunities">
                <div className="container career-box1">
                    <div className="title">
                        <h2 className="text-animate">UI/UX Designer</h2>
                    </div>
                    <div className="">
                        <div className="border-bb marg-rl"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-12 desc">
                            <div className="title">
                                <p className="text-animate">
                                    We are looking for a UI/UX Designer to turn our software into easy-to-use products for our clients. UI/UX Designer responsibilities include gathering user requirements, designing graphic elements and building navigation components. To be successful in this role, you should have experience with design software and wireframe tools. Ultimately, you’ll create both functional and appealing features that address our clients’ needs and help us grow our customer base.
                                </p>
                            </div>
                            <div className="title">
                                <h4 className="text-animate">Responsibilities</h4>
                            </div>
                            <div className="title">
                                <p className="text-animate">
                                    <ul>
                                        <li>Gather and evaluate user requirements in collaboration with product managers and engineers</li>
                                        <li>Illustrate design ideas using storyboards, process flows and sitemaps</li>
                                        <li>Design graphic user interface elements, like menus, tabs and widgets</li>
                                        <li>Build page navigation buttons and search fields</li>
                                        <li>Develop UI mockups and prototypes that clearly illustrate how sites function and look like</li>
                                        <li>Create original graphic designs (e.g. images, sketches and tables)</li>
                                        <li>Prepare and present rough drafts to internal teams and key stakeholders</li>
                                        <li>Identify and troubleshoot UX problems (e.g. responsiveness)</li>
                                        <li>Conduct layout adjustments based on user feedback</li>
                                        <li>Adhere to style standards on fonts, colors and images</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="title">
                                <h4 className="text-animate">Qualifications</h4>
                            </div>
                            <div className="title">
                                <p className="text-animate">
                                    <ul>
                                        <li>Proven work experience as a UI/UX Designer or similar role</li>
                                        <li>Portfolio of design projects</li>
                                        <li>Knowledge of wireframe tools (e.g. Wireframe.cc and InVision)</li>
                                        <li>Up-to-date knowledge of design software like Adobe Illustrator, Figma, Adobe XD</li>
                                        <li>Team spirit; strong communication skills to collaborate with various stakeholders</li>
                                        <li>Good time-management skills</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 desc">
                            <CareerForm postname="UI UX Designer" />
                        </div>
                    </div>
                </div>

                <div className="bg-opport">
                    <div className="container opp  text-animate">
                        <h5 className='border-bottom pb-5 text-center'>Other Opportunities</h5>
                        <div className="container border-bottom opp-box">
                            <a href='/opportunities/web-product-manager'>
                                <div className="row">
                                    <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                                        <h3>Web Product Manager</h3>
                                    </div>
                                    <div className='col-2 col-md-1'>
                                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                                    </div>
                                </div>
                            </a>
                        </div>

                        {/* <div className="container border-bottom opp-box">
                            <a href='/opportunities/react-native-developer'>
                                <div className="row">
                                    <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                                        <h3>React Native Developer</h3>
                                    </div>
                                    <div className='col-2 col-md-1'>
                                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="container border-bottom opp-box">
                            <a href='/opportunities/digital-marketing-specialist'>
                                <div className="row">
                                    <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                                        <h3>Digital Marketing Specialist</h3>
                                    </div>
                                    <div className='col-2 col-md-1'>
                                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                                    </div>
                                </div>
                            </a>
                        </div> */}

                        {/* <div className="container border-bottom opp-box">
                <a href='/opportunities/hr-manager'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>HR Manager</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div> */}
                        
                    </div>
                </div>

            </div>

            
            <Footer />
        </>
    )
}

export default Uiux
