import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { writerscafe, amelies, vapho } from '../Model/Project'

const Abbys = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Abbys</h2>
                <h3 className='text-animate'>A quick pick me up.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/c84cd4a3-desktop.jpg" alt="intellect-banner" />
        </div>

        {/* <div className="container text-animate position-relative">
            <div className="row bayleaf-ban-img">
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://staging.fingerprintscreative.com/wp-content/uploads/2021/02/Social-Media-Agency-butterchicken-compressed.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://staging.fingerprintscreative.com/wp-content/uploads/2021/02/dip2-compressed.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div> */}

        <div className="container text-animate position-relative">
            <div className="row bayleaf-ban-img">
                <div className="col-lg-5 col-md-5 p-0">
                    <div className="abbys-video">
                        <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/28912775-slider-disappearing.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 abbys-cont">
                    <div className="d-block">
                        <h4>Abbys</h4>
                        <p>Abby’s was a new entrant in the already crowded fast food market catering to a young audience with an offbeat menu - think butter chicken rolls, syrian beef fry sliders, chettinad homemade wafers. With such a quirky menu, we couldn’t let our marketing fall short - and we didn’t. Apart from it’s eye-catching branding, we created a host of exciting, memorable and quirky GIFs, videos and static content for Abby’s social media page. We created engaging content and used influencer marketing to drive trials. The numbers speak for themselves, making Abby’s the taste of the town.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/4dfdba55-fingerprints-abbys-social-media-marketing-insta-grid.jpg" alt="intellect-banner" />
        </div>

        <div className="container">
            <div className="row bayleaf-ban-img">
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/bc835cd8-achari-paneer-final.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/26c5dd9e-roll-or-slider.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>

                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/28912775-slider-disappearing.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/66eb4155-roll-disappearing.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>

                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/01e987a5-abbys1-1.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/fafec461-abbys2-1.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/db4f696d-abbys3-1.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/b293281a-abbys4-1.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/b5b635c0-abbys5-1.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/d95d1235-abbys6-1.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="col-lg-5 col-md-5 p-0 wdth">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/f1cbdc8e-starter.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-7 col-md-7 p-0 wdth0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/d1d633f5-lal-mass-slider.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div>


        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageUrl={writerscafe.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={amelies.title}
                            description={amelies.description}
                            pageUrl={amelies.pageUrl}
                            imageUrl={amelies.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vapho.title}
                            description={vapho.description}
                            pageUrl={vapho.pageUrl}
                            imageUrl={vapho.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Abbys
