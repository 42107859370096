import React from 'react'

const Projectworks = (props) => {
  return (
    <div className="project_works">
        <a href={props.pageUrl}>
          <video className="text-animate" src={props.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
          <h3 className='text-animate'>{props.title}</h3>
          <h4 className='text-animate'>{props.description}</h4>
        </a>
    </div>
  )
}

export default Projectworks