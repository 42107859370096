import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { mypersona, gbr, sunnybee } from '../Model/Project'


const Sequencelounge = () => {
  return (
    <>
        <Header />

        <div className="seq-lounge-head">
            <div className="container seq-lounge-head">
                <h2 className='text-animate'>Sequence Lounge</h2>
                <h3 className='text-animate'>Customizing an e-commerce website for <br className="d-none d-lg-block" /> customized designer clothing.</h3>
            </div>
        </div>

        <div className="seq-lounge-bgcl">
            <div className="container seq-lounge-img text-animate"           >
                <img className="img-fluid seq-lounge-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/6217ec03-sl5-1-2048x1567.png" alt="seq-lounge-banner" />
            </div>

            <div className="seq-lounge-obj">
                <div className="container seq-lounge-obj-cont">
                    <h5 className="border-bottom text-animate"         >OBJECTIVE</h5>
                    <p className='text-animate'>Sequence Lounge was founded out of a passion for creating well-cut and well-made garments made with niche quality natural fabrics that don’t adhere to standard sizing patterns. Our task was to create a modern and interactive e-commerce website that generated more traffic and sales yet spoke to a niche customer who valued designer-wear. The minimalistic yet contemporary design threw up different looks for the customer to browse through, ensuring they never got bored and took them through a journey that kept them engaged right up to check out, giving the client fantastic results.</p>
                </div>
            </div>

            <div className="seq-lounge-res">
                <div className="container seq-lounge-res-cont">
                    <h5 className="border-bottom text-animate"         >RESEARCH</h5>
                    <div className="row">
                        <div className="col-lg-5 col-md-6 text-animate"              >
                            <p>A failed product is equally as costly as research. We used Google Analytics and Google Tag Manager to collect quantitative data about the old website in order to identify the key pain spots for consumers.</p>
                        </div>
                        <div className="col-lg-1 d-none d-lg-block"></div>
                        <div className="col-lg-5 col-md-6 text-animate"           >
                            <img className="img-fluid seq-lounge-res-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4910d83e-bharathi-homes-image-1.png" alt="seq-lounge-banner" />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block"></div>
                    </div>
                </div>

                <div className="container seq-lounge-ul">
                    <ul className="seq-lounge-li text-animate"         >
                        <li>Low Conversion RATE (CTR)</li>
                        <li>Low Persuation states</li>
                        <li>Lack of brand credibility</li>
                    </ul>
                    <ul className="seq-lounge-li seq-lounge-li- text-animate"              >
                        <li>High Bounce Rates</li>
                        <li>High Drop off Rate</li>
                        <li>Low Pageview Time</li>
                    </ul>
                </div>
            </div>

            <div className="seq-lounge-wf">
                <div className="container seq-lounge-wf-cont text-animate"         >
                    <h5 className="border-bottom">WIREFRAMES</h5>
                </div>

                <div className="container text-animate"              >
                    <img className="img-fluid" style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/878bbaa9-sl4-1-2048x1515.png" alt="seq-lounge-banner" />
                </div>
            </div>

            
        </div>

        <div className="seq-lounge-bgcl1">
            <div className="container text-animate"         >
                <img className="img-fluid seq-lounge-pad" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/1a630c85-sl3-1-2048x1275.png" alt="seq-lounge-banner" />
            </div>
        </div>

        <div className="container seq-lounge-mob">
            <div  className="row">
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid" style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/eb5b46bb-s1.png" alt="seq-lounge-banner" />
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid" style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/4e770322-s2.png" alt="seq-lounge-banner" />
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"           >
                    <img className="img-fluid" style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/25e3ae7b-s3.png" alt="seq-lounge-banner" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={mypersona.title}
                            description={mypersona.description}
                            pageUrl={mypersona.pageUrl}
                            imageUrl={mypersona.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={gbr.title}
                            description={gbr.description}
                            pageUrl={gbr.pageUrl}
                            imageUrl={gbr.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageUrl={sunnybee.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Sequencelounge