import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { asianpaints, hilton, sunnybeebranding } from '../Model/Project'

const Mehtajewellery = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Mehta Jewellery Digital Campaign​</h2>
                <h3 className='text-animate'>Showcasing a heritage jewellery<br className="d-none d-lg-block d-md-block" /> brand in the digital age.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/7a4524b1-vdsdsgdgdg-1.png" alt="intellect-banner" />
        </div>

        <div className="container mehta-jewell-cont">
            <p>We created a series of digital campaigns to give the brand a more contemporary appeal. The first one, called Rhim Jhim, was designed around the theme of nature and involved beautifully designed watercolour backgrounds that were created to elevate and compliment the jewellery. These visuals highlighted the jewellery pieces which were inspired by nature. Animations were used in the form of water effects and ripples. The beautiful, art-led campaign created a positive impression of the brand digitally and increased the brand visibility significantly.</p>
        </div>

        <div className="container">
            <div className="row bayleaf-ban-img">
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c2c6a30c-post8_set2-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/444226ed-post2_set2-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-4 mehta-per">
                    <p>Impressions<br /> increased by<br /> <span>411%</span></p>
                </div>
                <div className="col-lg-4 col-md-4 mehta-per">
                    <p>ROI<br /> increased by<br /> <span>655%</span></p>
                </div>
                <div className="col-lg-4 col-md-4 mehta-per">
                    <p>Reach<br /> increased by<br /> <span>579%</span></p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div className="container">
            <div className="row bayleaf-ban-img">
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/cbaa4162-post1_set2-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/ff0f2b8e-set-3_post-5.mp4-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div>

        <div className="mehta-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 mehta-bg-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/88a134d5-groudp-411-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 mehta-bg-cont">
                        <div className="d-block">
                            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/906db64a-iktara_png-1.png" alt="intellect-banner" />

                            <p>Another campaign, Iktara, set the jewellery against a dazzling deep blue background and lotus leaves.By creating a beautifully designed grid, we were able to highlight individual pieces and yet tell a cohesive, compelling brand story. This digital campaign gained the maximum number of enquiries on Instagram and had a high number of engagements</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={asianpaints.title}
                            description={asianpaints.description}
                            pageUrl={asianpaints.pageUrl}
                            imageUrl={asianpaints.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={hilton.title}
                            description={hilton.description}
                            pageUrl={hilton.pageUrl}
                            imageUrl={hilton.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeebranding.title}
                            description={sunnybeebranding.description}
                            pageUrl={sunnybeebranding.pageUrl}
                            imageUrl={sunnybeebranding.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Mehtajewellery
