import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { writerscafe, abbys, vapho } from '../Model/Project'

const Desidi = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Desi Di</h2>
                <h3 className='text-animate'>Serving up a fresh look for<br className="d-lg-none d-md-none" /> Indian food.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/abea6cfc-graphicghost_free-wall-mockup.jpg" alt="intellect-banner" />
        </div>

        <div className="container desi-cont">
            <p>For a new eatery that served Indian food with a twist, we came up with a brand name, positioning, identity and all collaterals that made it stand out distinctly amongst the clutter. Desi Di - for ‘dhinchak’ food, was the name we coined and it was inspired by the restuarant’s creative take on traditional indian dishes like classic Khakras with funky toppings and dips, Gulab Jamun Cheesecake and Matka Chicken cooked in a clay pot.<br className="d-lg-none d-md-none" /><br className="d-lg-none d-md-none" /> The modern take on Indian classics extended to the in-restaurant branding elements including the menu, coasters, wall art and most importantly, social media which celebrated the unique Hinglish style of Desi Di’s communication, ultimately leading our customers to eat out of our hands</p>
        </div>
        
        <div className="container text-animate position-relative text-center">
            <img className="img-fluid desidi-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/0b0a2a91-group-46.svg" alt="intellect-banner" />

            <div className="row bayleaf-ban-img">
                <div className="col-md-6 bayleaf-ban-img desi-wdt">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/5df4803e-group-256.svg" alt="intellect-banner" />
                </div>
                <div className="col-md-6 bayleaf-ban-img desi-wdt">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/08a1d130-group-255.svg" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="container desi-margi">
            <div className="row bayleaf-ban-img">
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/35edb53d-umbrella-girl.gif" alt="intellect-banner" />
                </div>
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/e7600438-monkey-table.gif" alt="intellect-banner" />
                </div>

                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/1d39ebd4-car.gif" alt="intellect-banner" />
                </div>
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/b036e0fd-truck.gif" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="text-animate position-relative">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/e658abc0-untitled-1-2048x576.png" alt="intellect-banner" />
        </div>

        <div className="container desi-di-rest">
            <div className="desi-head">
                <h4>In-restaurant</h4>
            </div>

            <div className="row bayleaf-ban-img">
                <div className="col-md-12 desi-rest-wdt">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/5756b82d-desidi6.jpeg" alt="intellect-banner" />
                </div>

                <div className="col-md-6 desi-rest-wdt">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/0c7bcda7-screenshot-2021-05-17-at-12.12.53-pm-e1644477873850.png" alt="intellect-banner" />

                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/28629831-unnamed.jpeg" alt="intellect-banner" />
                </div>

                <div className="col-md-6 desi-rest-wdt">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/cca4b34f-desi-di-08-phone-lady.jpeg" alt="intellect-banner" />

                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/53bbeff1-wall-mock-3.jpg" alt="intellect-banner" />
                </div>

                <div className="col-md-12 desi-rest-wdt">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/46cca1ba-screenshot-2021-05-17-at-12.12.40-pm-2048x1346.png" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="text-animate position-relative">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/bc0b1cde-untitled-2-2048x600.png" alt="intellect-banner" />
        </div>

        <div className="container desi-di-media">
            <div className="desi-head">
                <h4>Social Media</h4>
            </div>

            <div className="row desi-img">
                <div className="col-md-4 desi-di-img">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/151c722e-17-07-1-1427x2048.png" alt="intellect-banner" />
                </div>
                <div className="col-md-4 desi-di-img">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/07bea2c5-16-06-1-1427x2048.png" alt="intellect-banner" />
                </div>
                <div className="col-md-4 desi-di-img">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/a2899fd9-15-05-1-1427x2048.png" alt="intellect-banner" />
                </div>
            </div>

            <div className="row desi-img">
                <div className="col-md-4 desi-di-img">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/66969a2e-15-04-1-1427x2048.png" alt="intellect-banner" />
                </div>
                <div className="col-md-4 desi-di-img">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/5a105d43-13-03-1-1427x2048.png" alt="intellect-banner" />
                </div>
                <div className="col-md-4 desi-di-img">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/b88c4967-1-02-1-1427x2048.png" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="container desi-di-lists">
            <ul>
                <li>Brand Name</li>
                <li>Brand Positioning</li>
                <li>Brand Identity</li>
            </ul>
            <ul>
                <li>In-restaurant</li>
                <li>Menu</li>
                <li>Coasters</li>
                <li>Social Media</li>
            </ul>
        </div>


        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageUrl={writerscafe.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={abbys.title}
                            description={abbys.description}
                            pageUrl={abbys.pageUrl}
                            imageUrl={abbys.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vapho.title}
                            description={vapho.description}
                            pageUrl={vapho.pageUrl}
                            imageUrl={vapho.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Desidi