import React from "react";
import ContactButton from "../Pages/Form";
// import gsap from 'gsap'
// import { Power3, TweenMax } from 'gsap/gsap-core';
// import CSSRulePlugin from 'gsap/CSSRulePlugin'

// import $ from 'jquery'

// $(document).ready(function(){
//   $('.contain ul.toggle').click(function(){
//       $(this).toggleClass('active');
//       $('.contain .sidebar').toggleClass('active');
//   })
// })

const Header = () => {
  // let textanim =useRef(null)

  // useEffect(() => {
  //     console.log(textanim);
  //     TweenMax.to(
  //         textanim,
  //         .8,
  //         {
  //             opacity: 1,
  //             y: -20,
  //             ease: Power3.easeOut
  //         }
  //     )
  // }, [])

  // let tl = gsap.timeline();

  //   tl.from(".txtanim", {
  //     duration: 0.75,
  //     y: 150,
  //     autoAlpha: 0,
  //     ease: Power3.out,
  //     stagger: 1.5,
  //     opacity: 1
  //   }).from("a .imgeff", {
  //     duration: 0.75,
  //     x: 300,
  //     autoAlpha: 0,
  //     ease: "elastic.out(1, 1)",
  //     stagger: {
  //       each: 0.75,
  //       amount: 0.5
  //     }
  //   }, "+=0.25");

  // let tl = gsap.timeline({ default: { ease: "power4.inOut", duration: 2}})
  // let navBar = CSSRulePlugin.getRule(".menu-btn:before")

  // tl.to('.cont-data', {'clip-path': 'polygon(0 100%, 100% 100%, 100% 0, 0 0)', opacity: 1, y: 0, duration: 2.2 })
  // tl.to('.sitelogo', {'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', opacity: 1, y: 0, duration: 2.2 })
  return (
    <>
      // <div className="lgbtq d-none"></div>
      <nav>
        <div className="navbar fixed-top">
          <div className="container nav-container">
            <input className="checkbox" type="checkbox" name="" id="" />
            <div className="hamburger-lines menu-bar">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <div className="site-logo">
              <a className="navbar-brand logo" href="/">
                <img
                  src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/da33fd4d-mainlogo.svg"
                  alt="Finger-Prints"
                />
              </a>
            </div>
            <div className="menu-items">
              <li>
                <a href="/about">about</a>
              </li>
              <li>
                <a href="/work">work</a>
              </li>
              <li>
                <a href="/services">services</a>
              </li>
              <li>
                <a href="/careers">careers</a>
              </li>
              {/* <li><a className='d-block d-lg-none form-btn'><ContactButton /></a></li> */}
              <li>
                <a href="/contact">contact</a>
              </li>

              <div className="cont-detil">
                <span>
                  For all new business inquiries, please contact us at{" "}
                  <a
                    href="mailto:aishwarya@fingerprintscreative.com"
                    style={{ color: "#fff" }}>
                    aishwarya@fingerprintscreative.com
                  </a>
                </span>
                {/* or call <a href="tel:+91 91761 11675" style={{color: "#fff"}}>+91 91761 11675</a> */}
                {/* karan - 98841 50604 */}
              </div>
            </div>
            <div className="header-btn d-none">
              <div className="footer__btn">
                {/* <a href="/contact">Get in touch</a> */}
                <ContactButton />
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <div className="dip-ind position-relative">
            <div className="bg-nav-color">
                <nav className="fixed-top container">
                    <input type="checkbox" id="active" />
                    <label for="active" class="menu-btn"><span></span></label>
                    <label for="active" class="close"></label>
                        <div className="site-logo">
                            <a className="navbar-brand logo" href="/"><img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/da33fd4d-mainlogo.svg" alt="Finger-Prints"/></a>
                        </div>
                    <div class="wrapper">
                        <div className='container'>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul className="r-1">
                                        <div className="cont-detil">
                                            <span className="cont-data">
                                                For all new business inquiries, please contact us at <a href="mailto:aishwarya@fingerprintscreative.com" style={{color: "#fff"}}>aishwarya@fingerprintscreative.com</a> or call <a href="tel:9884150604" style={{color: "#fff"}}>+91 98841 50604</a>
                                                <div className="mt-4">
                                                    <span className="fb-icon">
                                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                                    </span>
                                                    <span className="insta-icon">
                                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                                    </span>
                                                    <span className="linkedin-icon">
                                                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="r-2">
                                        <li><a href="/">About</a></li>
                                        <li><a href="/">Work</a></li>
                                        <li><a href="/">Services</a></li>
                                        <li><a href="/">Careers</a></li>
                                        <li><a href="/">Contact</a></li>
                                    </ul>
                                </div>
                                <div className="sitelogo d-lg-block d-md-block d-none">
                                    <a className="navbar-brand nav-img imgeff" href="/"><img src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/not%20afraid%20white.png" alt="Finger-Prints"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div> */}
      {/* <div className="fixed-top bg-white">
            <div className="container">
                <input type="checkbox" id="navcheck" role="button" title="menu" />
                <label htmlFor="navcheck" aria-hidden="true" title="menu">
                    <span class="burger">
                        <span class="bar">
                            <span class="visuallyhidden">Menu</span>
                        </span>
                    </span>
                </label>
                <div className="site-logo">
                    <a className="navbar-brand logo" href="/"><img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/da33fd4d-mainlogo.svg" alt="Finger-Prints"/></a>
                </div>
                <nav id="menu">
                    <a  className="txtanim" href="/">Lorem.</a> 
                    <a href="/">Nesciunt!</a>
                    <a href="/">Magnam.</a>
                    <a href="/">Ipsum.</a>
                    <a href="/">Voluptatem.</a>
                    <a href="/">Quibusdam.</a>
                </nav>
                
            </div>
        </div> */}
    </>
  );
};

export default Header;
