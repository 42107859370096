import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Careers = () => {
  return (
    <>
        <Header />

        <div className="container services careers">
            <div className="row">
                <div className="col-lg-6 cont">
                    <h1 className="text-animate">We’re fearless, <br className='d-none d-lg-block'/>independent &amp; Original</h1>
                    <p className="text-animate">We’re fearless collaborators, and we’re looking for people with the skills and ambition to make us even better.</p>
                </div>
                <div className="col-lg-6 image">
                <img className="img-fluid text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/05/bdddd4f8-fingerprints_creative_1586-1.png" alt="Finger-prints" />
                </div>
            </div>
        </div>

        <div className="container opp  text-animate">
            <h2 className='border-bottom pb-5'>Opportunities</h2>
            <div className='pt-5'>
                <p>No Current Openings</p>
            </div>
            {/* <div className="container border-bottom opp-box">
                <a href='/opportunities/ui-ux-designer'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>UI/UX Designer</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div>
            <div className="container border-bottom opp-box">
                <a href='/opportunities/Web-product-manager'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>Web Product Manager</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div> */}
            {/* <div className="container border-bottom opp-box">
                <a href='/opportunities/react-native-developer'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>React Native Developer</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div>
            <div className="container border-bottom opp-box">
                <a href='/opportunities/digital-marketing-specialist'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>Digital Marketing Specialist</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div> */}
            {/* <div className="container border-bottom opp-box">
                <a href='/opportunities/hr-manager'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>HR Manager</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div> */}
            
        </div>


        <Footer />
    </>
  )
}

export default Careers