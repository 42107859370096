import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { ewallet, loanoriginationsystem, futbank } from '../Model/Project'

const Sunnybee = () => {
  return (
    <>

        <Header />
        <div className="container sunny-bee border-bottom">
            <div className="row">
                <div className="col-lg-6 col-md-6 sunny-bee-cont-position">
                    <div className="d-block sunny-bee-cont">
                        <h4 className='text-animate'>SunnyBee</h4>
                        <h3 className='text-animate'>Taking a physical store online and bringing their customers to them.</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 text-animate"              >
                    <img className="img-fluid sunny-bee-img-wf" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/39a383db-sunnybeee-commerce.png" alt="sunny-bee-banner" />
                </div>
            </div>
        </div>

        <div className="container sunny-bee-obj">
            <div className="row">
                <div className="col-lg-6 col-md-6 sunny-bee-obj-text">
                    <h5 className='text-animate'>ABOUT SUNNYBEE</h5>
                    <p className='text-animate'>SunnyBee Market is your one-stop shop for food from around the world and around the corner - from trusted Indian brands to your global favorites. The choice of Indian and international staples, essentials, indulgences and treats make Sunny Bee Market your go-to destination for all your cooking needs.</p>
                </div>
                <div className="col-lg-6 col-md-6 sunny-bee-obj-text">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>To design a mobile app to make the shopping experience faster, easier and to help customers shop for everything under a minute.</p>
                </div>
            </div>
        </div>

        <div className="sunny-bee-desin">
            <div className="container sunny-bee-desin-text">
                <h5 className="border-bottom text-animate"         >DESIGN PROCESS</h5>
            </div>

            <div className="container">
                <div className="row pt-3">
                    <div className="col-lg-3 col-md-3 sunnybee-desin-ul">
                        <h5 className='text-animate'>Idea</h5>
                        <ul className="sunnybee-desin-li text-animate"           >
                            <li>Briefing</li>
                            <li>Research</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 sunnybee-desin-ul">
                        <h5 className='text-animate'>UX Analytics</h5>
                        <ul className="sunnybee-desin-li text-animate"           >
                            <li>Personas</li>
                            <li>User Journeys</li>
                            <li>User interview</li>
                            <li>Competitor analysis</li>
                            <li>Structure of the app</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 sunnybee-desin-ul">
                        <h5 className='text-animate'>UI Design</h5>
                        <ul className="sunnybee-desin-li text-animate"           >
                            <li>Wireframes</li>
                            <li>Interaction prototype</li>
                            <li>Visual Design</li>
                            <li>Design system</li>
                            <li>Presentation</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 sunnybee-desin-ul">
                        <h5 className='text-animate'>Testing</h5>
                        <ul className="sunnybee-desin-li text-animate"           >
                            <li>Usability testing</li>
                            <li>Transfer to development</li>
                            <li>User Feedback &amp; Improvements</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="sunnybee-card-se">
            <div className="container">
                <img className="img-fluid sunnybee-card-img text-animate"          src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7ccb1572-sunnybeedesign-process.png" alt="sunny-bee-banner" />
                <div className="sunnybee-card-cont">
                    <h5 className='text-animate'>Card Sorting Excercise</h5>
                </div>
            </div>
        </div>

        <div className="sunnybee-usrflow">
            <div className="container sunnybee-usrflow-cont">
                <h5 className="border-bottom text-animate"         >USER FLOW</h5>

                <div className="row pt-2">
                    <div className="col-lg-6 col-md-6 d-flex flex-wrap align-items-center">
                        <div className="sunnybee-usrflow-text d-block text-animate"              >
                            <p >The flowchart represents the user <br className="d-none d-lg-block" /> journey in the app. Each stem can go into <br className="d-none d-lg-block" /> detail depending on the user preference.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"           >
                        <img className="img-fluid sunnybee-usrflow-img " src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4b94fafd-sunnybeeuser-flow.png" alt="sunny-bee-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="sunnybee-wf">
            <div className="container sunnybee-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAME</h5>

                <div className="row sunnybee-wf-img">
                    <div className="col-lg-3 col-md-3 text-animate"         >
                        <img className="img-fluid sunnybee-wf-img1" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ecee3285-categories_wireframe.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-3 col-md-3 text-animate"              >
                        <img className="img-fluid sunnybee-wf-img2" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/56ca0019-checkout-wireframe.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-3 col-md-3 text-animate"           >
                        <img className="img-fluid sunnybee-wf-img3" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7978622d-cart-wireframe.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-3 col-md-3 text-animate"           >
                        <img className="img-fluid sunnybee-wf-img4" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e1012367-frequently-wireframe.png" alt="sunny-bee-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="sunnybee-desinsys">
            <div className="container sunnybee-desinsys-cont">
                <h5 className="border-bottom text-animate"         >DESIGN SYSTEM</h5>

                <div className="row sunnybee-desinsys-img0">
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid sunnybee-desinsys-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/709d1a4b-sunnybeecolors.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"           >
                        <img className="img-fluid sunnybee-desinsys-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/8900a76a-sunnybeebuttons.png" alt="sunny-bee-banner" />
                    </div>
                </div>

                <div className="row sunnybee-desinsys-img0">
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid sunnybee-desinsys-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/d4781d2a-sunnybeelabels.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"           >
                        <img className="img-fluid sunnybee-desinsys-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4de55b34-sunnybeeitemcard.png" alt="sunny-bee-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="sunnybee-anim">
            <div className="container sunnybee-anim-cont">
                <h5 className="border-bottom text-animate"         >ANIMATIONS</h5>

                <div className="row sunnybee-anim-video border-bottom">
                    <div className="col-lg-6 col-md-6">
                        <div className="sunnybee-video1">
                            <div className="sunnybee-video1-h text-animate"              >
                                <video className="sunnybee-video" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ee181d6e-splash-screen-2.mp4-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="sunnybee-video2">
                            <div className="sunnybee-video1-h text-animate"           >
                                <video className="sunnybee-video" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cd5e4ab6-sunnybee-splash-screen-animation-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container sunnybee-anim">
                <div className="row sunnybee-anim-align-rep">
                    <div className="col-lg-6 col-md-6 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="d-block sunnybee-anim-text">
                            <h4 className='text-animate'>AI Powered Search</h4>
                            <p className='text-animate'>Search is one of the core <br className="d-none d-lg-block" /> functionalities we have built within <br className="d-none d-lg-block" /> the app. In fact, we have built in AI <br className="d-none d-lg-block" /> right within the search which helps <br className="d-none d-lg-block" /> the user to find the right item he is <br className="d-none d-lg-block" /> looking for.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid sunnybee-anim-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/9863c314-ai-powered-search.png" alt="sunny-bee-banner" />
                    </div>
                </div>
                <div className="row sunnybee-anim-align-rep1">
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid sunnybee-anim-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/22bb6afc-quick-easy-payments.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="d-block sunnybee-anim-text">
                            <h4 className='text-animate'>Quick &amp; Easy Payments</h4>
                            <p className='text-animate'>Wallet is built within the app to make <br className="d-none d-lg-block" /> the payments quicker and easier. <br className="d-none d-lg-block" /> Load your wallet with money once <br className="d-none d-lg-block" /> and checkout instantly with just a tap.</p>
                        </div>
                    </div>
                </div>
                <div className="row sunnybee-anim-align-rep">
                    <div className="col-lg-6 col-md-6 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="d-block sunnybee-anim-text">
                            <h4 className='text-animate'>Convinient Delivery Slots</h4>
                            <p className='text-animate'>Choose delivery slots at your <br className="d-none d-lg-block" /> preferred day and time or if you want <br className="d-none d-lg-block" /> something quicker we’ve provided <br className="d-none d-lg-block" /> the express delivery option to deliver <br className="d-none d-lg-block" /> your order within 90 minutes.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid sunnybee-anim-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/d587ff07-convenient-delivery-slots.png" alt="sunny-bee-banner" />
                    </div>
                </div>
                <div className="row sunnybee-anim-align-rep1">
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid sunnybee-anim-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/bed4e26a-reachability.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="d-block sunnybee-anim-text">
                            <h4 className='text-animate'>Reachability</h4>
                            <p className='text-animate'>Primary actions like search, cart and <br className="d-none d-lg-block" /> checkout are designed keeping in <br className="d-none d-lg-block" /> mind the size of the phone screens. <br className="d-none d-lg-block" /> All important actions are placed in <br className="d-none d-lg-block" /> the very bottom of the screen for one <br className="d-none d-lg-block" /> handed operation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={ewallet.title}
                            description={ewallet.description}
                            pageUrl={ewallet.pageUrl}
                            imageUrl={ewallet.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={loanoriginationsystem.title}
                            description={loanoriginationsystem.description}
                            pageUrl={loanoriginationsystem.pageUrl}
                            imageUrl={loanoriginationsystem.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageUrl={futbank.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
    
  )
}

export default Sunnybee