import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { freshey, shuddha, sunnybeebread } from '../Model/Project'

const Sunnybeehoney = () => {
  return (
    <>

      <Header />

      <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>SunnyBee: Honey</h2>
                <h3 className='text-animate'>Good design for good health.</h3>
            </div>
      </div>

      <div className="container text-animate">
            <img className="img-fluid vinveli-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/c7c1831a-honey-cover-2.png" alt="sunnybeehoney" />
      </div>

      <div className='container text-animate hon-sec2'>
          <div className="row img-honey">
                <div className="col-lg-5 col-md-12 cont-honey">
                    <h2>A range of 11 delicious honey flavors that are safe, pure and responsibly harvested.</h2>
                </div>
                <div className="col-lg-1">
                </div>
                <div className="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                    <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/36bcbdc8-honey-bottle-1.png" alt="sunnybeehoney" />
                </div>
          </div>
      </div>

      <div className='container-fluid text-animate hon-sec3'>
        <div className="container mid">
            <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/159b17d0-honey-bottle-3.png" alt="sunnybeehoney" />
        </div>
      </div>

      <div className='container text-animate hon-sec4'>
            <h2>Unpasteurized, Unprocessed and 100% natural honey.<br className='d-none d-lg-block d-md-block'/> No chemicals. No added sugar. No additives.</h2>
            <div className="row">
                <div className="col-md-4">
                <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/ecb570b3-honey-bottle-2.png" alt="sunnybeehoney" />
                </div>
                <div className="col-md-4">
                <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/3c04e87b-honey-bottle-4.png" alt="sunnybeehoney" />
                </div>
                <div className="col-md-4">
                <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/81610561-honey-bottle-5.png" alt="sunnybeehoney" />
                </div>
            </div>
      </div>

      <div className='container text-animate hon-sec5'>
        <img className='img-fluid' style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/4b739710-cover.png" alt="sunnybeehoney" />
      </div>

      

      <div className="container">
            <div className="border-bb"></div>
        </div>
        

      <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={freshey.title}
                            description={freshey.description}
                            pageUrl={freshey.pageUrl}
                            imageUrl={freshey.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={shuddha.title}
                            description={shuddha.description}
                            pageUrl={shuddha.pageUrl}
                            imageUrl={shuddha.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeebread.title}
                            description={sunnybeebread.description}
                            pageUrl={sunnybeebread.pageUrl}
                            imageUrl={sunnybeebread.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

      <Footer />
    </>
  )
}

export default Sunnybeehoney
