import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Thankyou = () => {
  return (
    <>
        <Header />

        <div className="container tq-pg">
            <div className="tq-pg-cont">
                <h5 >Thanks so much for reaching out to us!</h5>
                <p>We will reach out to you shortly.</p>
                {/* <p >Please book a time slot that works for you from the calendly link given below:</p>
                <p ><a href="https://calendly.com/aishwaryadaswani">https://calendly.com/aishwaryadaswani</a></p> */}
                <div className="tq-pg__btn">
                    <a href="/">Back to Home</a>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Thankyou