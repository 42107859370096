import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { vinveli, intellect, oswinply } from '../Model/Project'

const Asianpaints = () => {
  return (
    <div className='asian'>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Asian Paints</h2>
                <h3 className='text-animate'>Adding Asian Paints to our brand palette.</h3>
            </div>
        </div>

        <div className="container text-animate">
            <img className="img-fluid bayleaf-ban-img p-0" src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/ap-1.png" alt="Asian_Paints" />
        </div>

        <div className="container asian-slide">
            <div className="row">
                <div className="col-lg-6 ">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/ap-2.png" alt="Asian_Paints" />
                </div>

                <div className="col-lg-6 asianpaint-para d-none d-lg-block d-md-block">
                    <p>Asian Paints commissioned Fingerprints to identify colour patterns and trends across Tamil Nadu to effectively convey these trends in their annual colour book. We engaged in a marketing research project which went on over months, delving deep into our roots, to discover the essence of true South Indian home styling.
                    <br /><br />
                    Not only did we identify, style and shoot different archetypes of South Indian styles but we went as far as conceptualizing and creating specialised wall stencils for different homes. These finally made it into a highly stylized colour book which captured the essence of Tamil Nadu’s homes, down to the very last shade.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 asian-paint-para">
                    <div className="d-block">
                        <p>Fingerprints engaged in months long marked research project which helped us identify the color patterns and trends all across Tamil Nadu. This was followed by conceptualizing of wall stencil designs , Styling, Photography and communication colour books.</p>

                        <ul>
                            <li>Market Research</li>
                            <li>Wall Stencil Design</li>
                            <li>Styling</li>
                            <li>Photography</li>
                            <li>Marketing Colour Tool Books</li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/ap-3.png" alt="Asian_Paints" />
                </div>
            </div>

            <div className="row asian-img">
                <div className="col-lg-4 asian-wdth p-0">
                    <img className="img-fluid asian-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4dd5253e-asian-paints-biryani-house-269-copy.jpg" alt="Asian_Paints" />
                </div>
                <div className="col-lg-8 asian-wdth p-0">
                    <img className="img-fluid asian-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/d02363ce-asian-paints-ramapuram-house-777-hdr-copy-2048x1365.jpg" alt="Asian_Paints" />
                </div>
            </div>
        </div>

        <div className="container">
            <div id="carouselExampleControls" className="carousel slide asian-slide-carousel0" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cc3e10c0-screenshot-2021-05-27-at-2.40.23-pm.png" className="d-block img-fluid" alt="Asian_Paints" />
                    </div>
                    <div className="carousel-item">
                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4aba68ba-screenshot-2021-05-27-at-2.40.45-pm.png" className="d-block img-fluid" alt="Asian_Paints" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="row asian-img">
                <div className="col-lg-6 col-md-6 asian-slide-carousel">
                    <img src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/ap-5.png" className="d-block img-fluid" alt="Asian_Paints" />
                </div>
                <div className="col-lg-6 col-md-6 asian-slide-carousel">
                    <div id="carouselExampleControls" className="carousel slide asian-img" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/41e86e4b-screenshot-2021-05-27-at-2.41.35-pm.png" className="d-block img-fluid" alt="Asian_Paints" />
                            </div>
                            <div className="carousel-item">
                                <img src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/ap-4.png" className="d-block img-fluid" alt="Asian_Paints" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div className="container asian-img">
            <div className="row asian-des-txt">
                <h4>Stencil Design</h4>
                <div className="col-lg-7 col-md-7 p-0">
                    <img className="img-fluid asian-img-design" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e47ec9e0-screenshot-2021-05-27-at-3.05.52-pm.png" alt="Asian_Paints" />
                </div>
                <div className="col-lg-5 col-md-5 p-0">
                    <img className="img-fluid asian-img-design0" src="https://storage.googleapis.com/stateless-staging-fingerprints/New%20React%20files/ap-6.png" alt="Asian_Paints" />
                </div>

                <h4>Colour Tool Book</h4>

                <img className="img-fluid asian-img asi-padd" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a874e49b-cover3-2048x1536.jpg" alt="Asian_Paints" />

                <img className="img-fluid asian-img asi-padd" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/3b621b71-spread4-2048x1536.jpg" alt="Asian_Paints" />

                <img className="img-fluid asian-img asi-padd" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/677d5d65-spread7-2048x1536.jpg" alt="Asian_Paints" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinveli.title}
                            description={vinveli.description}
                            pageUrl={vinveli.pageUrl}
                            imageUrl={vinveli.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={intellect.title}
                            description={intellect.description}
                            pageUrl={intellect.pageUrl}
                            imageUrl={intellect.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={oswinply.title}
                            description={oswinply.description}
                            pageUrl={oswinply.pageUrl}
                            imageUrl={oswinply.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </div>
  )
}

export default Asianpaints