import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { asianpaints, lexotique, kitchenji } from '../Model/Project'

const Oswinply = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Oswin Ply​</h2>
                <h3 className='text-animate'>A doorway to more<br className="d-none d-lg-block" /> customers for Oswin Ply.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/842382f7-1-446-1.png" alt="intellect-banner" />
        </div>

        <div className="container mehta-jewell-cont">
            <p>We created an animated short film that traces the journey of Oswin Plywood from procurement to the finished product. A quirky style, a brisk VO and a fascinating storyline to make a strong, lasting impression and grow its dealer network.</p>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b3307f56-2-1.png" alt="intellect-banner" />
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/0ac151bf-fds3-1.png" alt="intellect-banner" />
        </div>

        <div className="container position-relative">
            <div className="text-animate">
                <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/0a14fd28-oswin-ply-1.m4v' muted controls preload="auto" loop playsInline controlsList="nodownload" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={asianpaints.title}
                            description={asianpaints.description}
                            pageUrl={asianpaints.pageUrl}
                            imageUrl={asianpaints.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={lexotique.title}
                            description={lexotique.description}
                            pageUrl={lexotique.pageUrl}
                            imageUrl={lexotique.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={kitchenji.title}
                            description={kitchenji.description}
                            pageUrl={kitchenji.pageUrl}
                            imageUrl={kitchenji.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Oswinply
