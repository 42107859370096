import Footer from '../Components/Footer'
import Header from '../Components/Header'
import CareerForm from './CareerForm'
import { Helmet } from 'react-helmet'

const DpmJson = {
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "title" : "Digital Product Manager",
    "description" : "<p>The web project manager plays an important role in the growth of Fingerprint’s UI UX Department. He/She will manage digital projects of varying sizes and complexity using agile methods, working with business stakeholders and a solution delivery team to develop new features, implement user interface (UI) changes, and implement or develop key applications.</p>",
    "identifier": {
      "@type": "PropertyValue",
      "name": "Fingerprints",
      "value": "1234567"
    },
    "datePosted" : "2022-05-26",
    "validThrough" : "2022-12-26T00:00",
    "employmentType" : "FULL_TIME",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : "Fingerprints",
      "sameAs" : "https://www.fingerprintscreative.com/opportunities/web-product-manager",
      "logo" : "https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/da33fd4d-mainlogo.svg"
    },
    "jobLocation": {
    "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "15/4, 3rd Floor, Haddows Lane, Haddows Road, Nungambakkam, Chennai - 600006",
      "addressLocality": "Chetpet",
      "addressRegion": "Chennai",
      "postalCode": "600031",
      "addressCountry": "India"
      }
    }
  }


const WebPM = () => {


    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(DpmJson)}
                </script>
            </Helmet>
            <Header />

            <div className="opportunities">
                <div className="container career-box1">
                    <div className="title">
                        <h2 className="text-animate">Web Product Manager</h2>
                    </div>
                    <div className="">
                        <div className="border-bb marg-rl"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 desc">
                            <div className="title">
                                <p className="text-animate">
                                The web project manager plays an important role in the growth of Fingerprint’s UI UX Department. He/She will manage digital projects of varying sizes and complexity using agile methods, working with business stakeholders and a solution delivery team to develop new features, implement user interface (UI) changes, and implement or develop key applications.<br /><br />

                                The role holder will possess a thorough understanding of the scope and capabilities of modern web technologies, as well as strong project management skills gained in an agile, digital environment and good interpersonal and communication skills. They will manage product backlogs, create roadmaps and release schedules, and manage risks and issues proactively. They will define requirements in collaboration with users and the business.The role holder will serve as a vital link between the user experience (UX), content, and software development teams, ensuring that design changes and new features are defined in the most appropriate ways for front end and back end developers. Although this is not a hands-on technical role, they will understand the needs of developers and technical staff and be able to effectively communicate with them. The web project manager will be a member of the group web team, with the head of group web holding the formal reporting line.
                                </p>
                            </div>
                            <div className="title">
                                <h4 className="text-animate">Responsibilities:</h4>
                            </div>
                            <div className="title">
                                <p className="text-animate">
                                    <ul>
                                        <li>Plan projects and assign resources effectively, taking into account constraints and flagging potential resource shortfalls in good time</li>

                                        <li>In partnership with the web development team leader direct the work of web developers (in-house and external) to achieve project goals</li>

                                        <li>Interpret user and business needs and outputs from UI, visual and content design and turn them into appropriate specifications for web developers</li>

                                        <li>Plan and execute user acceptance testing for all development projects, including directing and managing independent testers</li>

                                        <li>Research and scope new requirements and assist in the design of UI components</li>

                                        <li>Work with clients to analyse business requirements, scope, and technicalities associated with the product.</li>

                                        <li>Track project plans to ensure milestones are met on a consistent basis.</li>

                                        <li>Single Point Of Contact for all communications from the client to the team</li>

                                        <li>Manage changes to the scope, schedule, costs, and timelines for the project.</li>

                                        <li>Create project plans from the scope.</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="title">
                                <h4 className="text-animate">Must Haves:</h4>
                            </div>
                            <div className="title">
                                <p className="text-animate">
                                    <ul>
                                        <li>Significant project management experience in a web/digital context, using primarily agile methods, and working on projects of various sizes and complexity.</li>

                                        <li>Experience in working with web content management systems and large, complex websites. Experience in directing and motivating staff not under direct line management responsibility.</li>

                                        <li>Good research, analytical and problem-solving skills. Organisational and prioritisation skills.</li>

                                        <li>Strong verbal and written communication skills, including the ability to communicate with others at all levels and translate technical jargon into clear English. Ability to learn quickly and to respond positively to a rapidly changing work area.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-5 desc">
                            <CareerForm postname="Web Product Manager" />
                        </div>
                    </div>
                </div>

                <div className="bg-opport">
                    <div className="container opp  text-animate">
                        <h5 className='border-bottom pb-5 text-center'>Other Opportunities</h5>
                        <div className="container border-bottom opp-box">
                            <a href='/opportunities/ui-ux-designer'>
                                <div className="row">
                                    <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                                        <h3>UI/UX Designer</h3>
                                    </div>
                                    <div className='col-2 col-md-1'>
                                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                                    </div>
                                </div>
                            </a>
                        </div>

                        {/* <div className="container border-bottom opp-box">
                            <a href='/opportunities/react-native-developer'>
                                <div className="row">
                                    <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                                        <h3>React Native Developer</h3>
                                    </div>
                                    <div className='col-2 col-md-1'>
                                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="container border-bottom opp-box">
                            <a href='/opportunities/digital-marketing-specialist'>
                                <div className="row">
                                    <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                                        <h3>Digital Marketing Specialist</h3>
                                    </div>
                                    <div className='col-2 col-md-1'>
                                        <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                                    </div>
                                </div>
                            </a>
                        </div> */}

                        {/* <div className="container border-bottom opp-box">
                <a href='/opportunities/hr-manager'>
                    <div className="row">
                        <div className='col-10 col-md-11' style={{alignSelf: 'center'}}>
                            <h3>HR Manager</h3>
                        </div>
                        <div className='col-2 col-md-1'>
                            <img src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/01/Group-31.png" alt="Finger-prints" />
                        </div>
                    </div>
                </a>
            </div> */}
                        
                    </div>
                </div>

            </div>

            
            <Footer />
        </>
    )
}

export default WebPM
