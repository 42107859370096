import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import QR from '../Assets/Icons/Vector.svg'
import Reminder from '../Assets/Icons/Vector-1.svg'
import Paycard from '../Assets/Icons/Group.svg'
import Seeourthinking from '../Components/Seeourthinking'
import { ewallet, authorcafe, sunnybee } from '../Model/Project'


const Futbank = () => {
  return (
    <>

        <Header />
        <div className="container fut-bank">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-cont-position">
                    <div className="d-block fut-bank-cont">
                        <h4 className='text-animate'>FUT Bank</h4>
                        <h3 className='text-animate'>Creating a brand-agnostic <br className="d-none d-lg-block" /> banking app you can bank on.</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-img text-animate"              >
                    <img className="img-fluid fut-bank-img-wf" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/5f623250-fut-bank-main.png" alt="fut-bank-banner" />
                </div>
            </div>
        </div>

        <div className="container fut-bank-overview">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-abov">
                    <h5 className='text-animate'>ABOUT FUT BANK</h5>
                    <p className='text-animate'>For FUT Bank, we created a readily accessible banking application that gives both users and banks a seamless experience. A powerful scalable core banking application, it gave the bank the control it needed while making sure the user experience was absolutely smooth and simple.</p>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-obj">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>Right from the wire-frame design upwards, we worked on designing an accessible interface that is intuitive and easy to navigate. Whether bill payments, money transfers or checking balance, our design made the FUT Bank app one you can definitely bank on.</p>
                </div>
            </div>
        </div>

        <div className="fut-bank-user">
            <div className="container fut-bank-user-cont">
                <h5 className="border-bottom text-animate"              >USER FLOW</h5>

                <img className="img-fluid fut-bank-user-img text-animate"          style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/10178761-fut-bank-userflow.png" alt="fut-bank-banner" />

                <img className="img-fluid fut-bank-user-img-rep text-animate"               style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/35e74397-fut-bank-userflowmobile.png" alt="fut-bank-banner" />
            </div>
        </div>

        <div className="container fut-bank-wf">
            <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>

            <img className="img-fluid fut-bank-wf-img text-animate"               style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ef681a1c-fut-bank-wireframes@2x-1-2048x1081.png" alt="fut-bank-banner" />
        </div>

        <div className="container fut-bank-design">
            <h5 className="border-bottom text-animate"         >DESIGN SYSTEM</h5>

            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <h6 className='text-animate'>Colors</h6>
                    <img className="img-fluid fut-bank-design-img text-animate"               style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/eea75ed1-fut-bank-color.png" alt="fut-bank-banner" />
                </div>
                <div className="col-lg-6 col-md-6">
                    <h6 className='text-animate'>Buttons</h6>
                    <img className="img-fluid fut-bank-design-img text-animate"               style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/b8696c99-fut-bank-button.png" alt="fut-bank-banner" />
                </div>
            </div>
        </div>

        <div className="container fut-bank-acc">
            <h5 className='text-animate'>Account overview cards</h5>

            <img className="img-fluid fut-bank-acc-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4e085d11-fut-bank-asset-4.png" alt="fut-bank-banner" />
        </div>

        <div className="container fut-bank-des text-center">
            <p className='text-animate'>As a scalable core banking application, Fut Bank app <br className="d-none d-lg-block d-md-block" /> development includes iOS and Android versions, web <br className="d-none d-lg-block d-md-block" /> applications, system emails, marketing materials, and more.</p>

            <img className="img-fluid fut-bank-des-img text-animate"          src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/6fe9af92-fut-bank-asset-5.png" alt="fut-bank-banner" />
        </div>

        <div className="fut-bank-bg">
            <div className="container fut-bank-bill position-relative">
                <h5 className='text-animate'>Easy Bill Payments</h5>
                <p className='text-animate'>Have all your expenses recorded on one platform. Fut Bank app provides a <br className="d-none d-lg-block d-md-block" /> unified payment experience with various payment options, reminders, <br className="d-none d-lg-block d-md-block" /> multiple account integration, options to defer payments and more.</p>
            </div>

            <div className="container fut-bank-pay">
                <div className="row">
                    <div className="col-lg-4 col-md-4 fut-bank-payment">
                        <div className="d-block">
                            <img className="img-fluid fut-bank-icon text-animate"          src={Reminder} alt="fut-bank-banner" />
                            <div className="fut-bank-pay-cont">
                                <h5 className='text-animate'>Payment <br /> reminders</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 fut-bank-payment">
                        <div className="d-block">
                            <img className="img-fluid fut-bank-icon0 text-animate"          src={Paycard} alt="fut-bank-banner" />
                            <div className="fut-bank-pay-cont">
                                <h5 className='text-animate'>Multiple account <br /> payment options</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 fut-bank-payment">
                        <div className="d-block">
                            <img className="img-fluid fut-bank-icon1 text-animate"          src={QR} alt="fut-bank-banner" />
                            <div className="fut-bank-pay-cont">
                                <h5 className='text-animate'>QR code UPI <br /> payment options</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container fut-bank-">
                <img className="img-fluid fut-bank-pay-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/eaebd912-fut-bank-mobileapp.png" alt="fut-bank-banner" />

                <img className="img-fluid fut-bank-pay-img-rep text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/c587ffaa-fut-bank-mobileapp-phone.png" alt="fut-bank-banner" />
            </div>
        </div>

        <div className="container">
            <img className="img-fluid fut-bank-flow-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f9802bea-fut-bank-mobileapp2.png" alt="fut-bank-banner" />
        </div>

        <div className="container fut-bank-sec">
            <div className="row fut-bank-sec-row">
                <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                    <div className="d-block fut-bank-sec-cont">
                        <h5 className='text-animate'>Reachability by design</h5>
                        <p className='text-animate'>FUT is designed with reachability in mind. User <br className="d-none d-lg-block" /> behavior is studied and all primary actions are <br className="d-none d-lg-block" /> placed in a comfortable reach area with just one <br className="d-none d-lg-block" /> finger and secondary actions are placed little further.<br className="d-none d-lg-block" /> Important information is displayed at the top.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid fut-bank-sec-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/b50b9954-fut-bank-reachability.png" alt="fut-bank-banner" />
                </div>
            </div>
        </div>

        <div className="container-fluid fut-bank-vdo p-0 text-animate"              >
            <video style={{width: "100%"}} src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/03/fut-bank.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
        </div>

        <div className="container-fluid fut-bank-vdo-rep p-0 text-animate"              >
            <video style={{width: "100%"}} src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/fe628002-fut-video.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
        </div>

        <div className="container fut-bank-ld">
            <div className="row">
                <div className="col-lg-6 col-md-6 text-animate"              >
                    <img className="img-fluid fut-bank-sec-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/2ee2cb2a-fut-bank-lightdark.png" alt="fut-bank-banner" />
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-ld-cont">
                    <div className="d-block">
                        <h5 className='text-animate'>Light &amp; Dark modes</h5>
                        <p className='text-animate'>Not only functionality, Fut Bank provides for <br className="d-none d-lg-block" /> aesthetic appeal also. Dark mode protects your eyes <br className="d-none d-lg-block" /> by emitting less light and also saves battery life. You <br className="d-none d-lg-block" /> can switch between the two with just a swipe.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid fut-bank-video">
            <div className="row">
                <div className="col-lg-6 col-md-6 p-0 text-animate"         >
                    <video style={{width: "100%"}} src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/03/003.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0 text-animate"              >
                    <video style={{width: "100%"}} src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/03/002.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div>

        <div className="container fut-bank-sec">
            <div className="row">
                <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                    <div className="d-block fut-bank-sec-cont">
                        <h5 className='text-animate'>Beneficiary Bank accounts</h5>
                        <p className='text-animate'>Fut Bank automatically fetches all the bank accounts <br className="d-none d-lg-block" /> of your beneficiaries instantly with just a mobile <br className="d-none d-lg-block" /> number or a social media account. You can perform <br className="d-none d-lg-block" /> any inter and intra bank payments quickly and<br className="d-none d-lg-block" /> easily.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 text-animate"              >
                    <img className="img-fluid fut-bank-sec-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/1eaf08f2-fut-bank-beneficiary.png" alt="fut-bank-banner" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={ewallet.title}
                            description={ewallet.description}
                            pageUrl={ewallet.pageUrl}
                            imageUrl={ewallet.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageUrl={authorcafe.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageUrl={sunnybee.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Futbank