import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { curiohh, authorcafe, sunnybeebranding } from '../Model/Project'

const Curiohhtravel = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Curiohh - Travel Merchandise​</h2>
                <h3 className='text-animate'>Inspiring a new generation of adventurers.</h3>
            </div>
        </div>

        <div className="curiohh-trvl-bg">
            <div className="container text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/ed1a65b2-curiohdfda-1.png" alt="intellect-banner" />
            </div>
        </div>

        <div className="container">
            <div className="row curiohh-trvl">
                <div className="col-lg-6 col-md-6 curiohh-trvl-cont">
                    <p>Born from the insight that a lot of us have seen cliche travel souvenirs that have been forgotten somewhere at the back of the shelf, Curiohh understood that passionate travel enthusiasts wanted memorabilia that kept the everyday adventurer inspired.</p>
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/3b20f06a-comp-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>

            <div className="row curiohh-trvl2">
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/7102d1a5-comp-2.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 curiohh-trvl-cont">
                    <p>We've helped Curiohh design and develop products that their audience could really relate to, and served as perfect ways to commemorate trips, capture memories, inspire adventures, and spread the love for travel</p>
                </div>
            </div>
        </div>

        <div className="curiohh-trvl-bg">
            <div className="container text-animate">
                <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9df91b0c-web-banner-5-sfd1-2-1.png" alt="intellect-banner" />
            </div>
        </div>

        <div className="container position-relative curiohh-trvl3">
            <div className="row bayleaf-ban-img">
                <div className="col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/83bd0d84-comp-4.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/cc737a28-comp-3.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div>

        <div className="curiohh-trvl-bg">
            <div className="container-fluid text-animate text-center">
                <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c1f077ef-sgdgsd-1.png" alt="intellect-banner" />
            </div>
        </div>

        <br />
        <br />

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={curiohh.title}
                            description={curiohh.description}
                            pageUrl={curiohh.pageUrl}
                            imageUrl={curiohh.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeebranding.title}
                            description={sunnybeebranding.description}
                            pageUrl={sunnybeebranding.pageUrl}
                            imageUrl={sunnybeebranding.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageUrl={authorcafe.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Curiohhtravel
