import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { bharathihomes, loanoriginationsystem, futbank } from '../Model/Project'

const Authorcafe = () => {
  return (
    <>
        <Header />

        <div className="author-head">
            <div className="container author-head-cont">
                <h2 className='text-animate'>Authorcafé</h2>
                <h3 className='text-animate'>Redesign for a website that’s <br className="d-none d-lg-block" /> designed for academic research.</h3>
            </div>
        </div>

        <div className="container text-animate">
            <img className="img-fluid author-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e8cdf630-authorcafe-main-2048x933.png" alt="author-banner" />
        </div>

        <div className="container author-about">
            <div className="row">
                <div className="col-lg-6 col-md-6 author-about-cont">
                    <h5 className='text-animate'>ABOUT AUTHORCAFÉ</h5>
                    <p className='text-animate'>AuthorCafé is a browser-based writing platform designed for academic research. It has been built to offer seamless collaboration with peers, provide access to your files and references in one place, and also has presentation and circulation-ready templates.</p>
                </div>
                <div className="col-lg-6 col-md-6 author-about-cont">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>To reposition, redesign, and market the product in new ways. The new website had to be optimized for both computer and mobile presence. It had to be designed in a way to build credibility for the brand and with features that enable marketing activities for the product and also ensure more signups for the free and paid version of Author Cafe.</p>
                </div>
            </div>
        </div>

        <div className="author-res">
            <div className="container author-res-cont">
                <h5 className="border-bottom text-animate">RESEARCH</h5>
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <p className='text-animate'>Rather than spending extra resources on research, we used Google Analytics and Google Tag Manager to collect quantitative insights about the old website to figure out the key pain points of the users.</p>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                    <div className="col-lg-5 col-md-6 text-animate"           >
                        <img className="img-fluid" style={{padding: "0 30px 10px 30px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4910d83e-bharathi-homes-image-1.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 author-ul">
                        <ul className="author-li text-animate"         >
                            <li>Low Conversion RATE (CTR)</li>
                            <li>Low Persuation states</li>
                            <li>Lack of brand credibility</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 author-ul author-ul-">
                        <ul className="author-li text-animate"              >
                            <li>High Bounce Rates</li>
                            <li>High Drop off Rate</li>
                            <li>Low Pageview Time</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="author-desin">
            <div className="container author-desin-text">
                <h5 className="border-bottom text-animate"         >DESIGN PROCESS</h5>
            </div>

            <div className="container author-desin-text-ul">
                <div className="row pt-3">
                    <div className="col-lg-3 col-md-3 author-desin-ul">
                        <h5 className='text-animate'>Idea</h5>
                        <ul className="author-desin-li text-animate"              >
                            <li>Briefing</li>
                            <li>Research</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 author-desin-ul">
                        <h5 className='text-animate'>UX Analytics</h5>
                        <ul className="author-desin-li text-animate"           >
                            <li>Personas</li>
                            <li>User Journeys</li>
                            <li>User interview</li>
                            <li>Competitor analysis</li>
                            <li>Nielsen heuristics</li>
                            <li>Structure of the website</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 author-desin-ul">
                        <h5 className='text-animate'>UI Design</h5>
                        <ul className="author-desin-li text-animate"           >
                            <li>Wireframes</li>
                            <li>Interaction prototype</li>
                            <li>Visual Design</li>
                            <li>Design system</li>
                            <li>Presentation</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 author-desin-ul">
                        <h5 className='text-animate'>Testing</h5>
                        <ul className="author-desin-li text-animate"           >
                            <li>Usability testing</li>
                            <li>Transfer to development</li>
                            <li>User Feedback &amp; Improvements</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="author-user">
            <div className="container author-user-cont">
                <h5 className="border-bottom text-animate"         >USER PERSONAS</h5>
            </div>

            <div className="container">
                <div className="row author-user-cont1">
                    <div className="col-lg-5 col-md-5">
                        <div className="row author-user-resp">
                            <div className="col-lg-3 col-md-3 text-animate"              >
                                <img className="img-fluid author-user-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/03e9beb5-authorcafe-sneha.png" alt="My-persona-banner" />
                            </div>
                            <div className="col-lg-5 col-md-8 author-user-data">
                                <div className="d-block">
                                    <h6 className='text-animate'>Sneha Malhotra</h6>
                                    <p className='text-animate'>University student</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Frustrations</h5>
                                <p className='text-animate'>Struggles to write her thesis paper. She faces difficulty with plagiarism and creating graphs and pie charts. She usually has to approach a printing press to templatize her research according to industry standards and needs help with formatting.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Goals</h5>
                                <p className='text-animate'>To get a distinction in her Master’s Degree and publish her journal in publishing companies like BMJ & Lanset.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row author-user-cont1">
                    <div className="col-lg-5 col-md-5">
                        <div className="row author-user-resp">
                            <div className="col-lg-3 col-md-3 text-animate"              >
                                <img className="img-fluid author-user-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f72ed0aa-authorcafe-anthony.png" alt="My-persona-banner" />
                            </div>
                            <div className="col-lg-5 col-md-8 author-user-data">
                                <div className="d-block">
                                    <h6 className='text-animate'>Anthony Joseph</h6>
                                    <p className='text-animate'>Professor, Physics</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Frustrations</h5>
                                <p className='text-animate'>Not satisfied with several online tools to manage and collaborate with students to help and guide them with their research papers. Has a whole bunch of research papers on his desk that he struggles to go through and manage.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Goals</h5>
                                <p className='text-animate'>Collate and collaborate with students and help them with their research papers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row author-user-cont1">
                    <div className="col-lg-5 col-md-5">
                        <div className="row author-user-resp">
                            <div className="col-lg-3 col-md-3 text-animate"              >
                                <img className="img-fluid author-user-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/fa987b3e-authorcafe-sivaramakrishnan.png" alt="My-persona-banner" />
                            </div>
                            <div className="col-lg-5 col-md-8 author-user-data">
                                <div className="d-block">
                                    <h6 className='text-animate'>Sivaramakrishnan</h6>
                                    <p className='text-animate'>Academic Publisher</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Frustrations</h5>
                                <p className='text-animate'>Dealing with a lot of hard copy material. Time-consuming to validate and combine material to publish.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Goals</h5>
                                <p className='text-animate'>Wants to read and review content quickly and collaborate with researchers to get their journals published.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="author-wf">
            <div className="container author-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>

                <img className="img-fluid author-wf-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/014237e4-authorcafe-wireframe-2048x1598.png" alt="My-persona-banner" />

                <img className="img-fluid author-wf-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7a50e6f9-authorcafe-wireframes-2-2048x1054.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="author-dsys">
            <div className="container author-dsys-cont">
                <h5 className="border-bottom text-animate"         >DESIGN SYSTEM</h5>

                <div className="row">
                    <div className="col-lg-6 col-md-6 author-dsys-text">
                        <h6 className='text-animate'>Primary Colours</h6>
                        <img className="img-fluid author-dsys-img text-animate"            src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/9c4c7877-authorcafe-colour.png" alt="My-persona-banner" />

                        <h6              >Secondary Colours</h6>
                        <img className="img-fluid author-dsys-img text-animate"            src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/91484a8a-authorcafe-secondary.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 author-dsys-text">
                        <h6              >Buttons</h6>
                        <img className="img-fluid author-dsys-img0 text-animate"            src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/79e0fa83-authorcafe-buttons.png" alt="My-persona-banner" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 author-dsys-text">
                        <h6 className='text-animate'>Input Fields</h6>
                        <img            className="img-fluid author-dsys-img1 text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/103cbc61-authorcafe-input-fields.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 author-dsys-text">
                        <h6 className='text-animate'>Iconography</h6>
                        <img className="img-fluid author-dsys-img1 text-animate"            src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/244293d8-authorcafe-iconography.png" alt="My-persona-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="author-cards">
            <div className="container author-cards-cont">
                <h5 className='text-animate'>Resuable blocks</h5>

                <div className="row pt-lg-5">
                    <div className="col-lg-4 col-md-4 text-animate"              >
                        <img className="img-fluid author-cards-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/b8085c9a-authorcafe-researchers.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-4 col-md-4 text-animate"           >
                        <img className="img-fluid author-cards-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ec05c083-authorcafe-universities.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-4 col-md-4 text-animate"           >
                        <img className="img-fluid author-cards-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/22fe82a2-authorcafe-publishers.png" alt="My-persona-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container author-act">
            <h5 className='text-animate'>Call to action section</h5>

            <img className="img-fluid author-act-img text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/b90dd5c0-authorcafe-footer-cta-2048x571.png" alt="My-persona-banner" />
        </div>

        <div className="author-ps">
            <div className="container author-ps-ill">
                <h5 className='text-animate'>Product screens &amp; Illustrations</h5>

                <div className="row pt-lg-5">
                    <div className="col-lg-4 col-md-4 text-animate"              >
                        <img className="img-fluid author-ill-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/abf893af-authorcafe-1.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-4 col-md-4 text-animate"           >
                        <img className="img-fluid author-ill-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/472507f1-authorcafe-2.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-4 col-md-4 text-animate"           >
                        <img className="img-fluid author-ill-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/2c3e33d0-authorcafe-3.png" alt="My-persona-banner" />
                    </div>
                </div>
            </div>

            <div className="container pt-lg-5 pt-md-4 text-animate"         >
                <img className="img-fluid author-site-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/1936f51a-authorcafe-web.png" alt="My-persona-banner" />
            </div>
            <div className="container text-animate"              >
                <img className="img-fluid author-site-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f734396f-authorcafe-monmob-2048x1242.png" alt="My-persona-banner" />
            </div>
            <div className="container pb-lg-5 text-animate"           >
                <img className="img-fluid author-site-img0" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/45a203ff-authorcafe-createacc-2048x1340.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={bharathihomes.title}
                            description={bharathihomes.description}
                            pageUrl={bharathihomes.pageUrl}
                            imageUrl={bharathihomes.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={loanoriginationsystem.title}
                            description={loanoriginationsystem.description}
                            pageUrl={loanoriginationsystem.pageUrl}
                            imageUrl={loanoriginationsystem.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageUrl={futbank.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Authorcafe