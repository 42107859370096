import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { sunnybeebranding, sunnybeehoney, sunnybee } from '../Model/Project'

const Sunnybeebread = () => {
  return (
    <>
    <Header />

    <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>SunnyBee: Breads</h2>
                <h3 className='text-animate'>Possibly the best thing <br className="d-none d-lg-block" /> sliced bread!</h3>
            </div>
    </div>

    <div className="container text-animate">
        <img className="img-fluid vinveli-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/f7162da6-asset-1.png" alt="vinveli-banner" />
    </div>

    <div className="container text-animate" style={{textAlign: "center"}}>
        <p className='sbbread'>For the newest offering from SunnyBee, we created packaging that brought out the essence of bread and depicted its journey from farm to table. We created custom-made, hand drawn illustrations that showed the source of the bread, the quality of ingredients used and freshness of the product in a unique and memorable way. Through consistency in quality and packaging, we were able to create a fresh new look for freshly baked SunnyBee bread which made it stand out on the shelves.</p>
    </div>

    <div className="container">
            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate" >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/bb826991-asset-13.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate">
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/1d67119b-asset-14.png" alt='vapho'/>
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate" >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/89db222a-asset-12.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate">
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/64b29782-asset-16.png" alt='vapho'/>
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate" >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/c7b3512d-asset-6.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/2b97e22e-asset-7.png" alt='vapho'/>
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate" >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/4f3a7f3a-asset-8.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/84b2169d-asset-9.png" alt='vapho'/>
                </div>
            </div>

            <img className="img-fluid " style={{paddingTop: "20px", paddingBottom: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/547b411d-asset-10.png" alt='vapho'/>

        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>
    <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeehoney.title}
                            description={sunnybeehoney.description}
                            pageUrl={sunnybeehoney.pageUrl}
                            imageUrl={sunnybeehoney.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageUrl={sunnybee.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeebranding.title}
                            description={sunnybeebranding.description}
                            pageUrl={sunnybeebranding.pageUrl}
                            imageUrl={sunnybeebranding.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
      
    </>
  )
}

export default Sunnybeebread
