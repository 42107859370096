import React, { Component, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import * as axios from "axios";
import swal from "sweetalert";
import Api from "../../package.json";
const qs = require("qs");
const CareerForm = ({ postname }) => {

    const [career, setCareer] = useState([]);
    const [careerCaptcha, setcareerCaptcha] = useState([]);
    const [postId, setpostID] = useState('');
    const [SubmitButton, setSubmitButton] = useState("Apply Now");
    const [resumeFile, setResumeFile] = useState(""); const {
        register,
        control,
        setValue,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const buttonRef = useRef();
    const onSubmit = (data) => {
        buttonRef.current.disabled = true;
        setSubmitButton("submitting...");
        save_careers(data);
    };
    const save_careers = async (data) => {
        const bodyFormData = new FormData();
        bodyFormData.append("post_id", 0);
        bodyFormData.append("post_name", postname);
        bodyFormData.append("fullname", data.fullname);
        bodyFormData.append("email", data.email);
        bodyFormData.append("mobilenumber", data.mobilenumber);
        bodyFormData.append("noticeperiod", data.noticeperiod);
        bodyFormData.append("currentsalary", data.currentsalary);
        bodyFormData.append("expectedsalary", data.expectedsalary);
        bodyFormData.append("salarynegotiable", data.salary);
        bodyFormData.append("linkedin", data.linkedin);
        bodyFormData.append("portfolio", data.portfolio);
        bodyFormData.append("resume", resumeFile);
        const response = await axios({
            method: "post",
            url: Api.userdata.url + "/save_careers",
            data: bodyFormData
        }
        );
        const json = await response.data;
        if (json["status"] == "OK") {
            swal("Thank you for your interest", json["message"], "success").then((value) => {
                window.location.reload(false);
            });
        } else {
            swal("Sorry you have already applied.", json["message"], "error");
        }
        buttonRef.current.disabled = false;
        setSubmitButton("Submit");
    };
    const onChangeResume = (e) => {
        console.log(e);
        //alert(e.target.files[0].type);

        if (e.target.files[0]) {
            if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png" || e.target.files[0].type == "application/pdf" || e.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                console.log("excel: ", e.target.files);
                setResumeFile(e.target.files[0]);

            } else {
                swal("Error !", "Invaild file format", "error");
                document.getElementById("resume").value = "";
            }
        }
    };
    return (
        <>
            <div className="container-fluid career-box-a">
                <div className="container">
                    <div className="row">
                        <div className="form-head m-0">
                            <h5 className="text-animate m-0 mb-0">Apply Now</h5>
                        </div>
                        <div className="col-md-12 opp-form text-animate">
                            <form className="form-group padd-lft" onSubmit={handleSubmit(onSubmit)}>
                                <input className="form-control marg-lft form-bdr" type="text" name="fullname" placeholder="Name"    {...register("fullname", {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    },
                                })} />
                                {errors.fullname && (
                                    <span class="errors">{errors.fullname.message}</span>
                                )}
                                <input className="form-control marg-lft form-bdr" type="email" name="email" placeholder="Email"
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Please enter your email id",
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address",
                                        },
                                    })} />
                                {errors.email && (
                                    <span class="errors">{errors.email.message}</span>
                                )}
                                <input className="form-control marg-lft form-bdr" type="number" name="mobilenumber" placeholder="Phone number"
                                    {...register("mobilenumber", {
                                        required: {
                                            value: true,
                                            message: "Please enter your mobile number",
                                        },

                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/i,
                                            message: "Invalid",
                                        },
                                    })} />
                                {errors.mobilenumber && (
                                    <span class="errors">{errors.mobilenumber.message}</span>
                                )}

                                <input className="form-control marg-lft form-bdr" type="number" name="noticeperiod" placeholder="Notice Period (In Days)"    {...register("noticeperiod", {
                                    required: {
                                        value: true,
                                        message: "Please enter your notice period",
                                    },
                                })} />

                                <input className="form-control marg-lft form-bdr" type="number" name="currentsalary" placeholder="Current Salary"    {...register("currentsalary", {
                                    required: {
                                        value: true,
                                        message: "Please enter your current salary",
                                    },
                                })} />

                                <input className="form-control marg-lft form-bdr" type="number" name="expectedsalary" placeholder="Expected Salary"    {...register("expectedsalary", {
                                    required: {
                                        value: true,
                                        message: "Please enter your expected salary",
                                    },
                                })} />

                                <p style={{marginTop: "32px"}}>Salary Negotiable</p>
                                <div className="form-check-inline">
                                <input type="radio" name="salary" value="yes"   {...register("salary", {
                                    required: {
                                        value: true,
                                        message: "Please select yes or no",
                                    },
                                })}/>
                                <label for="yes" style={{marginBottom: "10px", marginLeft: "8px"}}>Yes</label>
                                <input style={{marginLeft: "20px"}} type="radio"  name="salary"  {...register("salary")} value="no" />
                                <label for="no" style={{marginLeft: "8px"}}>No</label>
                                </div>

                                <input className="form-control marg-lft form-bdr" type="text" name="linkedin" placeholder="LinkedIn Profile"  {...register("linkedin")} />

                                <input className="form-control marg-lft form-bdr" type="text" name="portfolio" placeholder="Portfolio Profile"  {...register("portfolio")} />

                                <label className="resume">
                                    Upload Resume
                                </label>

                                <input className="form-control marg-lft mt-2 form-bdr bg-color" type="file" name="resume" placeholder="Resume"
                                    accept=".pdf, .jpg, .png, .docx"
                                    {...register("resume", {
                                        required: {
                                            value: true,
                                            message: "Please upload your resume",
                                        },
                                    })}
                                    onChange={onChangeResume} />
                                {errors.resume && (
                                    <span class="errors">{errors.resume.message}</span>
                                )}
                                <label className="filefom">*doc, docx, rtf, pdf</label>
                                <button className="form-control marg-lft form-bdr" ref={buttonRef}> {SubmitButton}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CareerForm