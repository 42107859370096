import React from 'react'

const Seeourthinking = (props) => {
  return (
    <div className="seeourthinking">
        <a href={props.pageUrl}>
        {/* <video className="text-animate" src={props.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
        <img style={{width: "100%"}} className="img-fluid" src={props.imageUrl} alt={props.title} />
        <h3 className="text-animate">{props.title}</h3>
        <h4 className="text-animate">{props.description}</h4>
        </a>
    </div>
  )
}

export default Seeourthinking