import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { vinveli, abbys, sunnybee } from '../Model/Project'

const Waycool = () => {
  return (
    <>
        <Header />

        <div className="container waycool-bann">
            <div className="row">
                <div className="col-lg-6 col-md-6 waycool-bann-cont">
                    <div className="d-block">
                        <h2 className='text-animate'>WayCool</h2>
                        <h3 className='text-animate'>Re-imagining India's food<br className="d-none d-lg-block d-md-block" /> supply chain.</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6" style={{padding: "10px"}}>
                    <div className="waycool-bann-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d7028ff3-fingerprints-waycool2-1-1.png" alt="intellect-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container waycool-over">
            <div className="row waycool-over-cont">
                <div className="col-lg-6 col-md-5 waycool-over-cont">
                    <h3>Branding one of India's fastest growing Agri-Tech companies</h3>
                </div>
                <div className="col-lg-6 col-md-7 waycool-over-cont">
                    <p>Tasked upon building a brand language and driving social impact while transforming India's food economy. WayCool aims to positively impact the lives of 500,000 farmers. With this goal, WayCool's operation has spread across product sourcing, food processing, branding and marketing, last mile distribution, and farm inputs. This gives WayCool end-to-end control over the food value chain.</p>
                </div>
            </div>
        </div>

        <div className="waycool-bg waycool-bg-cont">
            <div className="container">
                <p>WayCool currently handles 350+ tonnes of food products per day, across 18,900 clients, and from a network of 50,000+ farmers in more than 50 regions in India. WayCool’s products include staples like rice, pulses, wheat flour, dairy, and value added products.</p>
            </div>
        </div>

        <div className="container">
            <div className="row waycool-brand">
                <div className="col-lg-6 col-md-6">
                    <h4>BRANDING</h4>
                    <p>Branding is so much more than a logo. The true power in a brand lies in the identity of the brand, and understanding the brand personality is a great way to unlock a brand’s essence”</p>
                </div>
                <div className="col-lg-6 col-md-6">
                    <h4>CHALLENGE</h4>
                    <ul>
                        <li>Build Brand Personality, Brand Values, Brand Tone</li>
                        <li>Convey its core values to all key stakeholders with a strong Visual Identity</li>
                        <li>Develop a relationship between Brand and Employee</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="waycool-bg-strat">
            <div className="container waycool-strat-cont">
                <h4>STRATEGIZE</h4>
                <p>The plan of action commenced with creation of a strategy. Using a series of research methods, including personal interviews, surveys, and stakeholder analysis, Fingerprints developed Waycool’s new market positioning. After analyzing the collected data, Fingerprints identified Waycools brand pillars, voice, and messaging.</p>

                <div className="border">
                    <h3>Hungry. Humble. Humane.</h3>
                </div>
            </div>
        </div>

        <div className="container waycool-brand build">
            <h4>BUILD</h4>
            <p>With a strategy in place that aligned with the brand vision, Fingerprints established a robust brand<br /> foundation by focusing on two main objectives:</p>
            <ul>
                <li>Modernize Waycool’s brand perception</li>
                <li>Translate its vision to all key stakeholders</li>
            </ul>
        </div>

        <div className="container waycool-brand build">
            <h4>BUILD - IDENTITY</h4>
            <div className="waycool-img">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b9ead1c0-screenshot-2021-1e0-19-at-6.08-1-1.png" alt="intellect-banner" />
            </div>
        </div>

        <div className="waycool-bg-flo">
            <div className="container waycool-flo-cont">
                <div className="row">
                    <div className="col-md-5 waycool-flo">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e9e9876e-fram3e-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-7 waycool-flo">
                        <div className="d-block">
                            <p>A blossoming flower signifying constant growth. 3 leaves identifying the core values - Hungry, Humble &amp; Humane</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container text-center">
            <div className="bayleaf-ban-img">
                <img className="img-fluid bayleaf-ban-img marg-top" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b2993a7b-group-4033difference-1-768x118.png" alt="intellect-banner" />
            </div>

            <div className="row">
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/6d6b8945-waycool-identity-brand-guide1.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/1d63ed94-waycool-identity-brand-guide2-768x432.png" alt="intellect-banner" />
                </div>

                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/6d6b8945-waycool-identity-brand-guide1.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/107d961c-waycool-identity-brand-guide5-768x432.png" alt="intellect-banner" />
                </div>

                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c1ba00e3-waycool-identity-brand-guide6.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5b329a1e-waycool-identity-brand-guide7-768x432.png" alt="intellect-banner" />
                </div>

                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c1ba00e3-waycool-identity-brand-guide6.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5b329a1e-waycool-identity-brand-guide7-768x432.png" alt="intellect-banner" />
                </div>

                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/39ff72a9-waycool-identity-brand-guide8-768x432.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/29fad109-waycool-identity-brand-guide9.png" alt="intellect-banner" />
                </div>

                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b7e6af66-waycool-identity-brand-guide10.png" alt="intellect-banner" />
                </div>
                <div className="col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/fdbe00e3-waycool-identity-brand-guide4-768x432.png" alt="intellect-banner" />
                </div>

                    <div className="bayleaf-ban-img">
                        <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/38ca6448-s1445-1.png" alt="intellect-banner" />
                    </div>

                    <div className="bayleaf-ban-img">
                        <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/bb10b9f9-unsiform-1.png" alt="intellect-banner" />
                    </div>

                    <div className="bayleaf-ban-img">
                        <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a7844032-trucsk-1.png" alt="intellect-banner" />
                    </div>

                    <div className="bayleaf-ban-img">
                        <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2a19a1af-11s71-1.png" alt="intellect-banner" />
                    </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinveli.title}
                            description={vinveli.description}
                            pageUrl={vinveli.pageUrl}
                            imageUrl={vinveli.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={abbys.title}
                            description={abbys.description}
                            pageUrl={abbys.pageUrl}
                            imageUrl={abbys.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageUrl={sunnybee.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Waycool
