import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Seeourthinking from '../Components/Seeourthinking'
import { kokumstore, vinveliweb, authorcafe } from '../Model/Project'

const Mypersona = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>My Persona</h2>
                <h3 className='text-animate'>Redesigning a website for creators of authentic leaders.</h3>
            </div>
        </div>

        <div className="container text-animate">
            <img className="img-fluid my-perso-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/f0023d9e-group-2656-2048x1153.png" alt="My-persona-banner" />
        </div>

        <div className="my-perso-obj">
            <div className="container my-perso-obj-cont">
                <h5 className="border-bottom text-animate">OBJECTIVE</h5>
                <p className='text-animate'>My Persona helps organizations make a shift in their leadership space. Our task was to redesign and reposition their brand, starting with the website. Since the objective of the brand was to act as a catalyst to enhance the leadership pipeline in organizations, we wanted to reflect this philosophy with strong branding elements throughout the site. With customised illustrations and attention grabbing call-outs, we gave their website a fresh new look. We also enabled features for marketing activities. This helped the company garner more attention from the right sources and firmly establish themselves as creators of authentic leaders.</p>
            </div>
        </div>

        <div className="my-perso-res">
            <div className="container my-perso-res-cont">
                <h5 className="border-bottom text-animate">RESEARCH</h5>
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <p className='text-animate'>Research is expensive, so is a failed product. We used Google Analytics and Google Tag Manager to collect the quantitative insights about the old website to figure out the key pain points of the users.</p>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                    <div className="col-lg-5 col-md-6 text-animate">
                        <img className="img-fluid my-perso-res-img"  src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4910d83e-bharathi-homes-image-1.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                </div>
            </div>

            <div className="container my-perso-ul">
                <ul className="my-perso-li text-animate">
                    <li>Low Conversion RATE (CTR)</li>
                    <li>Low Persuation states</li>
                    <li>Lack of brand credibility</li>
                </ul>
                <ul className="my-perso-li my-perso-li- text-animate">
                    <li>High Bounce Rates</li>
                    <li>High Drop off Rate</li>
                    <li>Low Pageview Time</li>
                </ul>
            </div>
        </div>

        <div className="my-perso-wf">
            <div className="container my-perso-wf-cont">
                <h5 className="border-bottom text-animate">WIREFRAMES</h5>
            </div>

            <div className="container text-animate">
                <img className="img-fluid my-perso-img-wf" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/2a1e05bc-group-2657.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="my-perso-ill">
            <div className="container my-perso-ill-cont">
                <h5 className="border-bottom text-animate">ILLUSTRATIONS</h5>

                <p className='text-animate'>We used vibrant illustrations to represent what the brand delivers. <br className="d-none d-lg-block d-md-block" /> To showcase care, where people can build life.</p>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/385ce12b-mp1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/c8cd7f07-mp2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/0b07508b-mp3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/7b334350-mp4.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/10123a06-mp5.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/cdd9bf83-mp6.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/0f4ca847-mp7.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-6 col-md-6 my-perso-ill-video text-animate">
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/b3dc4c79-mp8.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>

                    <div className="col-lg-12 p-md-0 text-animate">
                        <img className="img-fluid img-ill" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/d2adb95e-mp2-1.png" alt="My-persona-banner" />
                    </div>

                    <div className="col-lg-12 my-persona-img-ill-0">
                        <img className="img-fluid img-ill-0 text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/25c84b21-mypersona4-1.png" alt="My-persona-banner"  />

                        <img className="img-fluid img-ill-01 text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/8d89188e-group-2554-1-2048x1215.png" alt="My-persona-banner"  />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={kokumstore.title}
                            description={kokumstore.description}
                            pageUrl={kokumstore.pageUrl}
                            imageUrl={kokumstore.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinveliweb.title}
                            description={vinveliweb.description}
                            pageUrl={vinveliweb.pageUrl}
                            imageUrl={vinveliweb.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageUrl={authorcafe.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </>
  )
}

export default Mypersona