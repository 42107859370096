import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { hilton, abbys, shuddha } from '../Model/Project'


const Aniva = () => {
  return (
    <>
        <Header />

        <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>Aniva</h2>
                <h3 className='text-animate'>Giving healthy drinks a whole new look.</h3>
            </div>
        </div>

        <div className="container text-animate"              >
            <img className="img-fluid vinveli-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/5c4296b7-a1-1.png" alt="vinveli-banner" />
        </div>

        <div className="container" style={{textAlign: "center"}}>
            <p className='aniva'>Aniva, a new product from the house of Hatsun Agro makes ready-to-drink flavoured whey drinks, breakfast drinks and milk mixes. Right from the logo to the packaging, we developed a new design language for the brand with customised illustrations and a special font. Designed to appeal to an urban, upmarket audience, the brand story was all about being healthy, hearty and wholesome. Breaking into a new category isn’t easy but when you have a distinctive design and packaging that practically jumps off the shelf then it makes it a little easier to make that leap.</p>

            <video className='text-animate' style={{paddingBottom: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/5d1e195f-logo.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            <img className="img-fluid vinveli-img text-animate" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4ccf22e4-a2-1.png" alt="vinveli-banner" />
            <img className="img-fluid vinveli-img text-animate" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a832a847-a3-1.png" alt="vinveli-banner" />

        </div>

        <div className="container-fluid aniva-bg text-animate">
            <div className="container">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/14027885-a4-1.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={abbys.title}
                            description={abbys.description}
                            pageUrl={abbys.pageUrl}
                            imageUrl={abbys.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={shuddha.title}
                            description={shuddha.description}
                            pageUrl={shuddha.pageUrl}
                            imageUrl={shuddha.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={hilton.title}
                            description={hilton.description}
                            pageUrl={hilton.pageUrl}
                            imageUrl={hilton.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
      
    </>
  )
}

export default Aniva
