import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { transformativedigital, authorcafe, futbank } from '../Model/Project'

const Ewallet = () => {
  return (
    <>

      <Header />
        <div className="container fut-bank">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-cont-position">
                    <div className="d-block fut-bank-cont">
                        <h4 className='text-animate'>Family Wallet</h4>
                        <h3 className='text-animate'>E-Wallet App for <br className="d-none d-lg-block" /> the family.</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-img text-animate"              >
                    <img className="img-fluid fut-bank-img-wf" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/caec721d-e-wallet-main.png" alt="fut-bank-banner" />
                </div>
            </div>
        </div>

        <div className="container fut-bank-overview">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-abov">
                    <h5 className='text-animate'>ABOUT FAMILY WALLET</h5>
                    <p className='text-animate'>Family Wallet is a completely integrated e-wallet solution that connects the whole family with the bank to provide real-time information, suggestions, and spends.</p>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-obj">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>To create an e-wallet app not just for one person, but for the entire family to manage their funds efficiently.</p>
                </div>
            </div>
        </div>

        <div className="fut-bank-user">
            <div className="container fut-bank-user-cont">
                <h5 className="border-bottom text-animate"         >IDEATION &amp; RESEARCH</h5>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 e-wallet-img text-center text-animate"         >
                        <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f65e8b21-mindmap-worked-e-wallet.png" alt="fut-bank-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 e-wallet-img0 text-center text-animate"              >
                        <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/31125e01-sticky-notes-worked-e-wallet.png" alt="fut-bank-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="sunnybee-wf">
            <div className="container sunnybee-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAME</h5>

                <div className="row e-wallet-wf-img">
                    <div className="col-lg-3 col-md-3 e-wallet-wf-img1 text-animate"         >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/af65fefd-e-wallet-asset-3@2x.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-3 col-md-3 e-wallet-wf-img2 text-animate"              >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/af65fefd-e-wallet-asset-3@2x.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-3 col-md-3 e-wallet-wf-img3 text-animate"           >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/77ec9164-e-wallet-asset-5@2x.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-3 col-md-3 e-wallet-wf-img4 text-animate"           >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/89575c31-e-wallet-asset-6@2x.png" alt="sunny-bee-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="author-user">
            <div className="container author-user-cont">
                <h5 className="border-bottom text-animate"         >USER PERSONAS</h5>
            </div>

            <div className="container">
                <div className="row author-user-cont1">
                    <div className="col-lg-5 col-md-4">
                        <div className="row author-user-resp">
                            <div className="col-lg-3 col-md-4 text-animate"              >
                                <img className="img-fluid author-user-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cccb13a8-e-wallet-aarthi.png" alt="My-persona-banner" />
                            </div>
                            <div className="col-lg-5 col-md-8 author-user-data">
                                <div className="d-block">
                                    <h6 className='text-animate'>Aarthi</h6>
                                    <p className='text-animate'>IT Professional</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Frustrations</h5>
                                <p className='text-animate'>She feels she is always spending a lot of money. Does not know how to track the money that is getting spent.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Goals</h5>
                                <p className='text-animate'>She wants to monitor her spending and find out where she can save money to invest in a house.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row author-user-cont1">
                    <div className="col-lg-5 col-md-4">
                        <div className="row author-user-resp">
                            <div className="col-lg-3 col-md-4 text-animate"              >
                                <img className="img-fluid author-user-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e9c57352-e-wallet-diya.png" alt="My-persona-banner" />
                            </div>
                            <div className="col-lg-5 col-md-8 author-user-data">
                                <div className="d-block">
                                    <h6 className='text-animate'>Diya</h6>
                                    <p className='text-animate'>School student</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Frustrations</h5>
                                <p className='text-animate'>Does not know how to manage money. Hates studying.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 author-user-data-txt">
                                <h5 className='text-animate'>Goals</h5>
                                <p className='text-animate'>She wants to be a gamer by profession. Wants to buy consoles and build a gaming PC.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="e-wallet-persol">
            <div className="container">
                <div className="e-wallet-persol-cont">
                    <h5 className='text-animate'>Personalised Dashboard</h5>
                </div>
            </div>

            <div className="container">
                <div className="row e-wallet-persol-img">
                    <div className="col-lg-6 col-md-6 text-animate"         >
                        <img className="img-fluid e-wallet-persol-img1" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/3988e38b-e-wallet-asset-9@2x.png" alt="fut-bank-banner" />
                        <p className="d-lg-none d-md-none">Parents</p>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid e-wallet-persol-img2" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/2f37bf57-e-wallet-asset-10@2x.png" alt="fut-bank-banner" />
                        <p className="d-lg-none d-md-none">Kids</p>
                    </div>
                </div>
            </div>
        </div>

            <div className="container fut-bank-sec">
                <div className="row e-wallet-lg">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                        <div className="d-block fut-bank-sec-cont">
                            <h5 className='text-animate'>Easy Login</h5>
                            <p className='text-animate'>One-click login option using your registered email ID <br className="d-none d-lg-block" /> or mobile number. Options for facial recognition and <br className="d-none d-lg-block" /> fingerprint login are also provided.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid fut-bank-sec-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cbc53d35-e-wallet-easy-login.png" alt="fut-bank-banner" />
                    </div>
                </div>
            </div>

            <div className="container fut-bank-ld">
                <div className="row">
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid fut-bank-sec-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/5174b661-e-wallet-goals.png" alt="fut-bank-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 fut-bank-ld-cont">
                        <div className="d-block">
                            <h5 className='text-animate'>Goals</h5>
                            <p className='text-animate'>Users can plan for something they want to buy. They <br className="d-none d-lg-block" /> can set a desired product’s value and work towards <br className="d-none d-lg-block" /> saving the amount to buy the product. This <br className="d-none d-lg-block" /> promotes the habit of saving and prudent spending <br className="d-none d-lg-block" /> which also helps the bank study their spending <br className="d-none d-lg-block" /> behavior and upsell loan products.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container fut-bank-sec">
                <div className="row e-wallet-lg">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                        <div className="d-block fut-bank-sec-cont">
                            <h5 className='text-animate'>Money Analyzer</h5>
                            <p className='text-animate'>Your money will never stay unused. Our API scans for <br className="d-none d-lg-block" /> idle money and generates intelligent money-saving <br className="d-none d-lg-block" /> suggestions that upsell investment products.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid fut-bank-sec-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/988a1ab0-e-wallet-money.png" alt="fut-bank-banner" />
                    </div>
                </div>
            </div>

        <div className="e-wallet-track">
            <div className="container">
                <div className="e-wallet-track-bg">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 e-wallet-track-cont">
                            <div className="d-block">
                                <h5 className='text-animate'>Track &amp; Set allowances</h5>
                                <p className='text-animate'>Track your child’s spending habits and set up monthly, <br className="d-none d-lg-block" /> weekly, daily allowances for your child to develop the <br className="d-none d-lg-block" /> habit of prudent saving and spending.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="e-wallet-track-img text-animate"              >
                                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/bf9c1fd2-e-wallet-asset-15@2x-1.png" alt="fut-bank-banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageUrl={authorcafe.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={transformativedigital.title}
                            description={transformativedigital.description}
                            pageUrl={transformativedigital.pageUrl}
                            imageUrl={transformativedigital.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageUrl={futbank.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


      <Footer />
    </>
  )
}

export default Ewallet