import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { freshey, vinveli, abbys } from '../Model/Project'

const About = () => {
  return (
    <>
        <Header />

        <div className="about-page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 about-ban-cont">
                        <h2 className="text-animate">When aesthetics and functionality meet</h2>
                        <p className="text-animate">Each identity is unique. Identifiable. Remarkable. Pointing a finger at a brand that dares, not just to think differently but do differently. Each piece of our creative work is crafted with passion and driven by strategy.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container about-ban-img text-animate">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/d0804367-notaf-1.png" alt="Finger-prints" />
        </div>

        <div className="about-bg">
            <div className="container about-bg-cont">
                <p className="text-animate">There are some things that keep us up at night. How do we do things differently? Say the same thing more compellingly? Create conversations that last? And impact our clients’ bottom lines as positively as we impact their brand health?</p>
            </div>
        </div>

        <div className="about-anim">
            <div className="container">
                <div className="aanim-cont">
                    <h2>driven</h2>
                </div>
                <div className="aanim-cont">
                    <p>We constantly push the envelope both in the digital world <br className="d-none d-lg-none d-md-block" /> and offline, to be innovative in what <br className="d-none d-lg-block" /> we say, how we say it,<br className="d-none d-lg-none d-md-block" /> where we say it or who we’re talking to.</p>
                </div>
                <div className="aanim-cont">
                    <div className="container d-lg-none d-md-none py-3">
                        <div className="border-bb"></div>
                    </div>
                    <h2>by</h2>
                    <div className="container d-lg-none d-md-none py-3">
                        <div className="border-bb"></div>
                    </div>
                </div>
                <div className="aanim-cont">
                    <p>We’re here to not just <br /> change the conversation but to lead it.</p>
                </div>
                <div className="aanim-cont">
                    <h2>truth​</h2>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="aanim-img ">
                            <img className="img-fluid cp-image-reveal" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/05/4f376578-fingerprints_creative_1589-1.png" alt="Finger-prints" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container aanim-creativ d-none d-lg-block">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <div className="aanim-cre-cont">
                            <p>As a culture, we foster an attitude of fearlessness, in strategy and creative thinking. We approach business problems without fear to create effective and impactful solutions rooted in brand purpose.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 aanim-cre-rotate">
                        <div className="aanim-cre">
                            <h2>creatively</h2>
                        </div>
                        <div className="aanim-cre">
                            <div className="aanim-cre-bord"></div>
                        </div>
                        <div className="aanim-cre">
                            <h2 className="adj-positi">fearless</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-lg-none">
                <div className="container aanim-creativ">
                    <div className="aanim-cre-resp">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Creatively<br className="d-none d-md-block" /> fearless</h2>
                            </div>
                            <div className="col-md-6">
                                <p>As a culture, we foster an attitude of fearlessness, in strategy and creative thinking. We approach business problems without fear to create effective and impactful solutions rooted in brand purpose.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="aanim-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/9b09459e-pxl_20210825_133509429-scaled-e1644480076817-1536x1182.jpg" alt="Finger-prints" />
                    </div>
                </div>
                <div className="container aanim-creativ">
                    <div className="aanim-cre-resp">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Strategically<br className="d-none d-md-block" /> sound</h2>
                            </div>
                            <div className="col-md-6">
                                <p>We believe that all great ideas are born out of deep-rooted strategic thinking. By diving into a brand’s purpose, we create measurable and successful strategic ideas that result in creative work that works.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    

        </div>

        <div className="container d-none d-lg-block theboutsec">
            <div className="row">
                <div className="col-lg-6 bout-con">
                    <h2>strategi<span>cally</span> <br/>sound</h2>
                </div>
                <div className="col-lg-6 bout-img">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/05/67609e82-fingerprints_creative_1632-1.png" alt="Finger-Prints" />
                    <div className='boxy'>
                        <p>We believe that all great ideas are born out of deep-rooted strategic thinking. By diving into a brand’s purpose, we create measurable and successful strategic ideas that result in creative work that works.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container about-gallery">
            <div className="row">
                <div className="col-lg-4 col-md-4 about-gall">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/0a2e39ff-frame-13.png" alt="Finger-Prints" />
                </div>

                <div className="col-lg-4 col-md-4 about-gall">
                    <img className="img-fluid" style={{height: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/05/81d17c17-fingerprints_creative_1736-1.png" alt="Finger-Prints" />
                </div>

                <div className="col-lg-4 col-md-4 about-gall">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/0924a273-vbccvb.png" alt="Finger-Prints" />
                </div>

                <div className="col-lg-4 col-md-4 about-gall">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/c1dc78d7-vbcvc.png" alt="Finger-Prints" />
                </div>

                <div className="col-lg-4 col-md-4 about-gall">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/8d311ba7-frame-12.png" alt="Finger-Prints" />
                </div>

                <div className="col-lg-4 col-md-4 about-gall">
                    <img className="img-fluid" style={{height: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/05/11ff272e-fingerprints_creative_1577-1.png" alt="Finger-Prints" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="seeourthinking">
                            <a href={freshey.pageUrl}>
                            <video className="text-animate" src={freshey.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            <h3 className="text-animate">{freshey.title}</h3>
                            <h4 className="text-animate">{freshey.description}</h4>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="seeourthinking">
                            <a href={abbys.pageUrl}>
                            <video className="text-animate" src={abbys.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            <h3 className="text-animate">{abbys.title}</h3>
                            <h4 className="text-animate">{abbys.description}</h4>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="seeourthinking">
                            <a href={vinveli.pageUrl}>
                            <video className="text-animate" src={vinveli.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            <h3 className="text-animate">{vinveli.title}</h3>
                            <h4 className="text-animate">{vinveli.description}</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default About