import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { sunnybeebread, vinitahospital, sunnybeehoney } from '../Model/Project'

// Not Completed

const Sunnybeebranding = () => {
  return (
    < >
      <Header />
      <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>SunnyBee - Branding</h2>
                <h3 className='text-animate'>Gathering goodnes for this <br className="d-none d-lg-block" /> neighbourhood grocery store.</h3>
            </div>
    </div>

    <div className="container text-animate">
        <video className="vinveli-img" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/e17dfe09-sunnybee-compressed.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
    </div>

    <div className="container text-animate" style={{textAlign: "center"}}>
        <p className='aniva'>SunnyBee Market had started off as a neighbourhood grocery store but wanted their presence to grow beyond this. They expanded their in-house product range to include everything from pulses, grains and everyday staples to locally sourced honey, ice creams, breads, spices and more. They wanted to increase their market share and we undertook a rebranding exercise for them to achieve this purpose. Through an omni-channel strategy we were able to reposition the brand and change the perception of SunnyBee from a very niche fruits and vegetable store to a local neighbourhood store where you could get everything your kitchen could possibly need under one roof.</p>
    </div>

    <div className="container text-animate">
        <div className="row">
            <div className="col-md-4 year year2">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/38f4d520-sunnybee-year-1@2x.png" alt="sunnybeebranding"/>
            </div>
            <div className="col-md-4 year year2">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/dd348a96-sunnybee-year-3@2x.png" alt="sunnybeebranding"/>
            </div>
            <div className="col-md-4 year year3 ">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/cb6990ac-sunnybee-now@2x.png" alt="sunnybeebranding"/>
            </div>
        </div>
    </div>

    <div className="container seq-lounge-mob">

        <div  className="row">
            <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/c37241e3-sb-yellow-bg@2x-768x627.png" alt='vapho'/>
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/3e337983-sb-green@2x-768x627.png" alt='vapho'/>
            </div>
        </div>

        <div  className="row brand-from-to">
            <div className="col-lg-6 col-md-6 justify-content-center text-animate"         >
                <h6>From:</h6>
                <h5>Fresh produce retail outlet bringing a wide range of fruits <br className='d-none d-lg-block'/> and vegetables, predominantly directly sourced from farms <br className='d-none d-lg-block'/> and farmers across the country and beyond.</h5>
            </div>
            <div className="col-lg-6 col-md-6 justify-content-center text-animate"              >
                <h6>To:</h6>
                <h5>Socially conscious neighbourhood food store, bringing <br className='d-none d-lg-block'/> farm fresh, FMCG, and curated food products from <br className='d-none d-lg-block'/> around the world and around the corner, to a <br className='d-none d-lg-block'/> passionate community of customers.</h5>
            </div>
        </div>

        <img className="img-fluid text-animate" style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/a623b91b-sb-employee@2x-2048x1173.png" alt='vapho'/>
        </div>

        <div className="container fut-bank-des text-center">
        <p className='text-animate'>Uniform design, signgage and wayfinding systems</p>
        <img className="img-fluid text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/9f9873f6-sb-sign-board.png" alt="vapho-banner" />
        </div>

        <div className="container seq-lounge-mob">

        <div  className="row">
            <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/a29f0e5a-sb-interior.png" alt='vapho'/>
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/82f04814-store-interior-2@2x.png" alt='vapho'/>
            </div>
        </div>

        <video className='text-animate' style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/eb533446-sunnybeewithchennaiyinfc.mp4" controls controlslist="nodownload" />

        <div  className="row">
            <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
            <video className='text-animate' style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/2db06b4b-video-3-dunzo.mp4" controls controlslist="nodownload" />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
            <video className='text-animate' style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/6275ffcc-video-4-dunzo.mp4" controls controlslist="nodownload" />
            </div>
        </div>

        </div>


        <div className="container fut-bank-des text-center">
        <p className='text-animate'>Brand Iconography</p>
        <img className="img-fluid text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/00ab4c71-brand-icons.jpg" alt="vapho-banner" />
        </div>

        <div className='container text-animate hon-sec2'>
          <div className="row img-honey">
                <div className="col-lg-5 col-md-12 cont-brand">
                    <h3>India’s first self-checkout <br className='d-none d-lg-block'/> grocery store</h3>
                    <h5>Considering the need for a safe shopping <br className='d-none d-lg-block'/> experience by limiting contact and to <br className='d-none d-lg-block'/> improve overall speed of checkouts.</h5>
                </div>
                <div className="col-lg-1">
                </div>
                <div className="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                    <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/93642492-selfie.png" alt="sunnybeehoney" />
                    <img className='img-fluid' src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/8da1d056-selfie-booth.png" alt="sunnybeehoney" />
                </div>
          </div>
      </div>

      <div className="container fut-bank-des text-center">
        <p className='text-animate'>Everything for the convinience of our customers. <br className='d-none d-lg-block'/> Trucks design and deployed.</p>
        <img className="img-fluid text-animate" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/101112d4-sb-van.png" alt="vapho-banner" />
    </div>


    <div className="container">
            <div className="border-bb"></div>
        </div>
    <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinitahospital.title}
                            description={vinitahospital.description}
                            pageUrl={vinitahospital.pageUrl}
                            imageUrl={vinitahospital.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeehoney.title}
                            description={sunnybeehoney.description}
                            pageUrl={sunnybeehoney.pageUrl}
                            imageUrl={sunnybeehoney.imageUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeebread.title}
                            description={sunnybeebread.description}
                            pageUrl={sunnybeebread.pageUrl}
                            imageUrl={sunnybeebread.imageUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </>
  )
}

export default Sunnybeebranding
